import React from 'react'
import Boxcard from './Boxcard';
import Topheader from './Topheader';
import Navbar from './Navbar';
import Footer from './Footer';


function Company() {
 
  return (
    <div>
         <Topheader />
      <Navbar />
      <section className='py-5'>
        <div className='container text-center'>
            <div className='row justify-content-center'>
              <div className='col-12 col-md-8'>
                  <h5 className='sub_title'><span className='title_line'></span>Company<span className='title_line'></span></h5>
                  <h1 className='mt-3 main_heading'>Our marketing services</h1>
                  <p className='fs_20 px-md-5'>Welcome to ElevateSphere, where we bring together a fusion of creativity and technical mastery to empower your brand's digital journey.</p>

                  <a href='Contact' className='btn default_btn py-2 my-3'>Contact us <i className="fa-solid fa-arrow-right ms-2"></i></a>
              </div>
              
            </div>
           

          </div>  
      </section>   

      <section className='pb-5 mb-5'>
        <div className='container text-center px-md-4'>
            <div className='row g-4 justify-content-center'>
              
              <div className='col-12 col-md-6'>
                  <div className='card bg5 article_card radius_20'>
                    <div className='card-body text-start p-5'>

                    <img src='./assets/image/company_icn1.png' className='card_icn2 mb-5'/>

                    <h2 className='card_head pe-md-5 my-3'>Web Development</h2>
                    <p className='text-muted fs_20 mb-5'>From cutting-edge web development to bespoke app solutions, our development team crafts robust, scalable, and tailored digital solutions that resonate with your business objectives.</p>
                    
                    <a href='#' className='fs_20'>Learn More <i className="fa-solid fa-arrow-right ms-2"></i></a>
                    </div>
                  </div>
              </div>

              <div className='col-12 col-md-6'>
                  <div className='card article_icn_bg2 article_card radius_20'>
                    <div className='card-body text-start p-5'>

                    <img src='./assets/image/company_icn2.png' className='card_icn2 mb-5'/>

                    <h2 className='card_head pe-md-5 my-3'>UI/UX Design</h2>
                    <p className='text-muted fs_20 mb-5 pb-md-4'>Elevate user experiences with designs that seamlessly blend aesthetic appeal and functional efficiency, fostering engagement and brand loyalty.</p>
                    
                    <a href='#' className='fs_20'>Learn More <i className="fa-solid fa-arrow-right ms-2"></i></a>
                    </div>
                  </div>
              </div>

              <div className='col-12 col-md-6'>
                  <div className='card article_icn_bg3 article_card radius_20'>
                    <div className='card-body text-start p-5'>

                    <img src='./assets/image/company_icn3.png' className='card_icn2 mb-5'/>

                    <h2 className='card_head pe-md-5 my-3'>Brand Identity</h2>
                    <p className='text-muted fs_20 mb-5'>Crafting identities that stand out in a crowded digital space, our branding solutions speak volumes and leave a lasting impact on your audience, ensuring your brand is unforgettable.</p>
                    
                    <a href='#' className='fs_20'>Learn More <i className="fa-solid fa-arrow-right ms-2"></i></a>
                    </div>
                  </div>
              </div>

              <div className='col-12 col-md-6'>
                  <div className='card bg3 article_card radius_20'>
                    <div className='card-body text-start p-5'>

                    <img src='./assets/image/company_icn4.png' className='card_icn2 mb-5'/>

                    <h2 className='card_head pe-md-5 my-3'>Digital Marketing</h2>
                    <p className='text-muted fs_20 mb-5'>Our marketing strategies are designed to drive engagement, leveraging the latest trends and insights to position your brand at the forefront of the digital landscape.</p>
                    
                    <a href='#' className='fs_20'>Learn More <i className="fa-solid fa-arrow-right ms-2"></i></a>
                    </div>
                  </div>
              </div>


            </div>
          </div>  
      </section>  

      <Boxcard/>
<Footer/>
    </div>
  )
}

export default Company
