import React,{ useEffect } from 'react'

function Topheader() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,hi,mr,bn,ml,or,kn,ta",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    if(addScript){
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    }
   
  }, []);
  return (
    <section className='bg-dark'>
      <div className='container'>
        <div className='row align-items-center py-2'>

          <div className='col-6 col-md-10'>
          <marquee><p className='top_head_p text_center'>This announcement bar can be used to inform visitors of <span className='important-text'> something important!</span></p></marquee>
          </div>

          <div className='col-6 col-md-2'>
            <div className='d-flex justify-content-end'>
                <i class="fa-solid fa-globe globe_icn"></i>
                <div id="google_translate_element"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Topheader
