import React from 'react';
import Lottie from 'react-lottie';
import imageAbout from '../components/image/Animation - 1707285901725.json';
import ImageLabs from '../components/image/Animation77.json';
import ImageLabs1 from '../components/image/Animation - 1707294001333 (1).json';
import Topheader from './Topheader';
import Navbar from './Navbar';
import Footer from './Footer';




function Press() {
  return (
    <div>
      <Topheader />
      <Navbar />
      <div className="container">
        <div className="presssec1">
          <div className="p_sec1">
            <div className="sec_img">
              <img src="./assets/image/logo.png" />
            </div>
            <h2>Press and Media Kit</h2>
            <p>If you need more information or would like to organize an interview or other press opportunity, please contact us at press trustid.one.</p>
          </div>
        </div>
        <div className="presssec2">
          <div className="press_Sec2_main">
            <div className="press_json">
              <Lottie options={{ loop: true, autoplay: true, animationData: ImageLabs1 }} />

            </div>
            <div className="press-content-sec2">
              <h2>About Trust Id</h2>
              <p>Trust Id is dedicated to turning your digital aspirations into tangible experiences. By simplifying and securing the identity verification process, Trust Id empowers users and businesses alike. Our platform bridges the gap between complex digital security needs and practical, user-friendly solutions, ensuring every digital interaction is built on a foundation of trust and efficiency.</p>
            </div>
          </div>
        </div>
        {/* section3 */}
        <div className="presssec2">
          <div className="press_Sec2_main">
            <div className="press-content-sec2">
              <h2>What is Trust Id ?</h2>
              <p>
                Our platform is designed to streamline the identity verification process, offering a seamless experience for both individuals and businesses. Through cutting-edge technology and robust security measures, we ensure that every interaction is protected against fraud and unauthorized access.We understand the challenges posed by complex digital security requirements, which is why we have developed practical solutions that prioritize user-friendliness without compromising on security.
              </p>
            </div>
            <div className="press_json">
              <Lottie options={{ loop: true, autoplay: true, animationData: ImageLabs }} />
            </div>
          </div>
        </div>
        {/* section 4 */}
        <div className="presssec4">
          <div style={{ textAlign: 'center', margin: '30px auto' }}>
            <h2 className="pressheading4">Press Coverage</h2>
          </div>
          <div className="dta-main" style={{ margin: '20px auto' }}>
            {/* Your press coverage content goes here */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Press
