import React, { useState } from "react";

function Aboutus() {
  const [activeButton, setActiveButton] = useState("mission");

  const handleMissionClick = () => {
    setActiveButton("mission");
  };

  const handleVisionClick = () => {
    setActiveButton("vision");
  };

  return (
    <section className="my-5 py-5">
      <div className="container " style={{ borderRadius: "15px" }}>
        <div className="row">
          <div
            className="col-12 col-md-8 card_bg_mob p-md-0"
            style={{ marginTop: "9px" }}
          >
            <div className="card card_border radius1">
              <div className="card-body text-start p-3">
                <h5>
                  <span class="title_line"></span>About us
                </h5>
                <h1 className="about_head">
                  Transforming Digital Visions Into Reality
                </h1>
                <p style={{ fontSize: "15px" }}>
                  Trust Id is dedicated to turning your digital aspirations into
                  tangible experiences. By simplifying and securing the identity
                  verification process, Trust Id empowers users and businesses
                  alike. Our platform bridges the gap between complex digital
                  security needs and practical, user-friendly solutions,
                  ensuring every digital interaction is built on a foundation of
                  trust and efficiency.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 card_bg_mob p-md-0 radius2">
            <div className="card card_border card_bg radius2">
              <div className="card-body text-start">
                <img
                  src="./assets/image/card_img.png"
                  className="mx-auto d-block"
                  style={{ width: "100%", height: "300px" }}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-8 card_bg_mob p-md-0 radius3">
            <div className="card card_border card_bg radius3">
              <div className="card-body text-start">
                <div className="bg-white p-2" style={{ borderRadius: "25px" }}>
                  <div className="row g-3">
                    <div className="col-12 col-md-6">
                      <a
                        onClick={handleMissionClick}
                        className={`btn about_btn1 mx-auto d-block ${
                          activeButton === "mission" ? "active" : ""
                        }`}
                      >
                        Identity Verification
                      </a>
                    </div>
                    <div className="col-12 col-md-6">
                      <a
                        onClick={handleVisionClick}
                        className={`btn about_btn1 mx-auto d-block ${
                          activeButton === "vision" ? "active" : ""
                        }`}
                      >
                        Foundation of Trust
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="bg-white p-3 mt-3"
                  style={{ borderRadius: "15px" }}
                >
                  {activeButton === "mission" ? (
                    <p style={{ fontSize: "20px" }}>
                      Identity Verification by Trust Id redefines security,
                      seamlessly integrating cutting-edge technology to ensure
                      every digital interaction is authenticated. Elevate your
                      digital trust, safeguarding personal and professional
                      exchanges in the digital realm.
                    </p>
                  ) : (
                    <p style={{ fontSize: "20px" }}>
                      {" "}
                      Trust Id lays a Foundation of Trust, embedding integrity
                      and security at the core of digital interactions. We
                      empower connections with reliable verification, creating a
                      safer, more trustworthy digital world for everyone.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4 card_bg_mob p-md-0 radius1">
            <div className="card card_border card_bg radius1">
              <div className="card-body">
                <div className="row g-4">
                  <div className="col-12 col-md-6">
                    <div className="card">
                      <div className="card-body text-center">
                        <img
                          src="./assets/image/icn5.png"
                          style={{ width: "50px" }}
                        />
                        <h5 className="mt-3 reliale_heading">
                          Reliable Verification
                        </h5>
                        <p className="reliale_para">
                          Trust Id ensures every digital interaction is backed
                          by robust, accurate identity verification, enhancing
                          security and confidence across transactions.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="card">
                      <div className="card-body text-center">
                        <img
                          src="./assets/image/icn6.png"
                          style={{ width: "50px" }}
                        />
                        <h5 className="mt-3 reliale_heading">
                          Enhanced Security
                        </h5>
                        <p className="reliale_para">
                          With advanced encryption and data protection
                          protocols, Trust Id fortifies the digital landscape,
                          safeguarding personal information against unauthorized
                          access.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Aboutus;
