import React from "react";
import Topheader from "./Topheader";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Ocl = () => {
  return (
    <>
       <Topheader />
      <Navbar />
      <div className="privacy_and_heading">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <div className="text-center">
                <h1 className="">One Click Login (OCL) </h1>
                {/* <p>
                  Trust Id Onboard Screening: Streamlining Secure Onboarding
                  Processes Introduction
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy_and_policy_area">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <h5 className="privacy_head">Introduction</h5>
              <ul>
                <li>
                  In an age where digital convenience is key, Trust Id’s One
                  Click Login (OCL) service revolutionizes how users access
                  platforms and services. By minimizing the steps required to
                  authenticate and log in, OCL provides a seamless, secure, and
                  efficient user experience. Enhancing User Experience with One
                  Click Login
                </li>
              </ul>
              <h5 className="privacy_head">Simplified Access</h5>
              <ul>
                <li>
                  OCL reduces the hassle of remembering multiple passwords or
                  navigating complex login procedures. With just one click,
                  users can access their accounts, making the login process
                  faster and reducing drop-off rates.
                </li>
              </ul>
              <h5 className="privacy_head">Increased Security</h5>
              <ul>
                <li>
                  Despite its simplicity, OCL does not compromise on security.
                  Trust Id utilizes advanced encryption and authentication
                  protocols to ensure that user data is protected during every
                  login attempt. Seamless Integration
                </li>
              </ul>

              <ul>
                <li>
                  OCL is designed to be easily integrated into any platform,
                  requiring minimal changes to existing infrastructure. This
                  ensures a smooth implementation and maintenance process for
                  businesses. How One Click Login Works <br />
                  <strong>Step 1:</strong> Initial Setup
                </li>
                <li>
                  Users register with Trust Id and opt for OCL during their
                  first login on a supported platform. This process involves a
                  one-time verification of their credentials.
                  <br /> <strong> Step 2:</strong> One Click Authentication
                </li>
                <li>
                  Once the initial setup is complete, users can log in to any
                  affiliated platform with a single click. Trust Id handles all
                  necessary security checks in the background, providing a
                  hassle-free login experience. <br />
                  <strong>Step 3:</strong> Multi-Platform Compatibility
                </li>
                <li>
                  OCL supports logins across multiple devices and platforms.
                  Whether it’s mobile apps, desktop applications, or web
                  services, users enjoy the same streamlined access everywhere.
                  Benefits of Trust Id’s One Click Login
                </li>
              </ul>
              <h5 className="privacy_head">For Users</h5>
              <ul>
                <li>
                  Convenience: Users enjoy a simplified login process without
                  repetitive authentication steps. Speed: Access services faster
                  with reduced login times, enhancing overall user satisfaction.
                  Reduced Friction: Minimize the chances of user drop-off due to
                  forgotten passwords or lengthy login procedures.
                </li>
              </ul>
              <h5 className="privacy_head">For Businesses</h5>
              <ul>
                <li>
                  <strong>Improved User Retention:</strong> A simplified login
                  process improves user engagement and retention rates.
                </li>
                <li>
                  <strong> Enhanced Security:</strong> Reduce the risk of
                  unauthorized access with robust security measures embedded in
                  OCL.
                </li>
                <li>
                  Easy Integration: Integrate OCL with existing systems without
                  significant development overhead or disruption to services.
                </li>
              </ul>
              <h5 className="privacy_head">Industries Benefiting from OCL</h5>
              <ul>
                <li>
                  {" "}
                  E-commerce: Streamline the checkout process for faster
                  transactions and improved customer experience.
                </li>
                <li>
                  Financial Services: Provide secure and quick access to banking
                  and financial platforms, enhancing customer trust and
                  satisfaction.
                </li>
                <li>
                  <strong> Healthcare: </strong>Enable patients and providers to
                  access medical records and services securely with minimal
                  hassle.
                </li>
                <li>
              <strong>  Education:</strong> Facilitate easy access to learning management systems and educational resources for students and faculty.
                </li>
                <li>
                Entertainment and Media: Allow users to swiftly access streaming platforms and digital content without the barriers of repetitive logins.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Ocl;
