import React from 'react'
import "../Pricing.css"
import Topheader from './Topheader'
import Navbar from './Navbar'
import Footer from './Footer'

function AppPaymentFailure() {
  return (
    <div>
    
      <div className="container">
        <div class="row justify-content-center">
          <div class="col-md-5">
            <div class="message-box _success _failed">
              <i class="fa fa-times-circle icon-shake" aria-hidden="true"></i>
              <h2> Your payment failed </h2>
              <p>  Try again later </p>
              {/* <a href="/paymentApp" className="retry-link">Retry Payment</a> */}

            </div>
          </div>
        </div>

      </div>
    
    </div>
  )
}

export default AppPaymentFailure
