import React from "react";
import Topheader from "./Topheader";
import Navbar from "./Navbar";
import Footer from "./Footer";

const DAAC = () => {
  return (
    <>
       <Topheader />
      <Navbar/>
      <div className="privacy_and_heading">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <div className="text-center">
                <h1 className="">DAC</h1>
                <p>
                  Trust Id Door Access Kit (DAC): Advanced Security for Enhanced
                  Access Control
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy_and_policy_area">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <h5 className="privacy_head">Introduction</h5>

              <ul>
                <li>
                  The Trust Id Door Access Kit (DAC) is a comprehensive access
                  control solution designed to secure entrances and exits across
                  various facilities. Combining state-of-the-art technology with
                  user-friendly features, the DAC ensures that only authorized
                  personnel can access secured areas, enhancing both security
                  and operational efficiency.
                </li>
              </ul>
              <h5 className="privacy_head">What is the Door Access Kit?</h5>

              <ul>
                <li>
                  The Door Access Kit is an all-in-one system that includes
                  everything needed to set up secure door access control. It
                  integrates seamlessly with existing security infrastructure,
                  making it easy to upgrade your security measures without
                  extensive modifications. Key Features
                </li>
                <li>
                  Biometric Authentication: Incorporates biometric technology,
                  such as fingerprint and facial recognition, for enhanced
                  security.
                </li>
                <li>
                  Remote Management: Manage access permissions remotely through
                  a centralized software platform, allowing for real-time
                  updates and adjustments.
                </li>
                <li>
                  Event Logging: Automatically logs all access events, providing
                  a detailed audit trail for security and compliance purposes.
                </li>
                <li>
                  Benefits of Using Trust Id Door Access Kit Enhanced Security
                </li>
                <li>
                  With biometric authentication and advanced encryption, the DAC
                  provides superior security, significantly reducing the risk of
                  unauthorized access.
                </li>
              </ul>
              <h5 className="privacy_head">Scalability</h5>
              <ul>
                <li>
                  Designed to accommodate the needs of both small businesses and
                  large enterprises, the DAC is scalable and can be expanded to
                  include more access points as needed.
                </li>
              </ul>
              <h5 className="privacy_head">Ease of Use</h5>
              <ul>
                <li>
                  The system is user-friendly, with a simple interface that
                  allows for quick setup and easy management of access
                  permissions. Integration Capabilities
                </li>
                <li>
                  Easily integrates with other security systems, including
                  surveillance cameras and alarm systems, providing a
                  comprehensive security solution. How Trust Id Door Access Kit
                  Works
                </li>
                <li>
                  <strong>Step 1:</strong>Installation <br />
                  Install the hardware components of the DAC at designated
                  access points. This includes biometric scanners, card readers,
                  and control panels.
                </li>
                <li>
                  <strong>Step 2:</strong>Configuration <br />
                  Configure the system settings, including access permissions
                  for different user groups, through the centralized management
                  software.
                </li>
                <li>
                  <strong>Step 3:</strong>Enrollment <br />
                  Enroll users into the system by capturing their biometric data or issuing access cards, depending on the chosen method of authentication.
                </li>
                <li>
                  <strong>Step 4:</strong>Operation <br />
                  The system becomes operational, controlling access based on the predefined permissions. All entry and exit events are logged automatically.
                </li>
                <li>
                    <strong>Step:5</strong>Monitoring and Maintenance <br />
                    Monitor the system through the management software, making adjustments as needed. Regular maintenance checks ensure optimal performance and security.
Applications of Trust Id Door Access Kit

                </li>
                <li>
                Corporate Offices: Control access to sensitive areas such as data centers and executive offices.
    Manufacturing Facilities: Secure access to production areas to ensure safety and protect intellectual property.
                </li>
                <li>
                Educational Institutions: Manage access to classrooms and faculty rooms, enhancing security for students and staff.
                </li>
                <li>
                Residential Complexes: Provide residents with secure, convenient access to their homes and community facilities.
                </li>
                <li>
                Healthcare Facilities: Secure areas with sensitive medical equipment or patient records, ensuring compliance with health privacy regulations.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default DAAC;
