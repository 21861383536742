import React from 'react'
import Lottie from 'react-lottie';
import carrerimg from '../components/image/tryustr.json';
import team1 from '../components/image/team_img1.jpg'
import team2 from '../components/image/team_img2.jpg'
import team3 from '../components/image/etwete.json'
import Topheader from './Topheader';
import Navbar from './Navbar';
import Footer from './Footer';
function Career() {
  return (
    <div>
         <Topheader />
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-6 col-xs-6 bg1-div">
            <h2 className="txt4">Career</h2>
          </div>
          <div className="col-lg-7 col-md-6 col-xs-6 bg2-div"></div>
        </div>
      </div>

      <div className="career">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-xs-12">
              <h1>Become a part of Trust Id</h1>
              <p>Join our vibrant team at  Trust Id, where we're transforming the Trust Id industry.</p>
              <button className="career_button">
                See open positions
              </button>
            </div>
            <div className="col-lg-5 col-md-6 col-xs-12">
              <div className="team_sec1_img">
                <Lottie options={{ loop: true, autoplay: true, animationData: carrerimg }} height={310} width={310} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="career_section py-5 my-5">

        <div className="container section_2_tittle">
          <h2>Our Culture</h2>
          <p>We at trust Id place a premium on our business and team cultures because we see them as fundamental to who we are as a company and as individuals. Each individual's point of view adds something special to our rich and varied culture.</p>
        </div>

        <div className="container">
          <div className="row section_card_m">

            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card">
                <h3>Sustained Performance</h3>
                <p>Our culture encourages long-term productivity via a combination of dedicated work time and time off. Work from anywhere, set your own schedule, and take as much time off as you need to keep performing at a high level.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card">
                <h3>Honest and open dialogue</h3>
                <p>We encourage a friendly and honest atmosphere. Our first priority is making the office a welcoming place where everyone feels comfortable sharing ideas and receiving constructive criticism.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card">
                <h3>Sharing of Happiness and Originality</h3>
                <p>We want to create a workplace where meetings and interactions are not only productive, but also fun. We appreciate the unique perspectives and out-of-the-box thinking of those who make decentralisation an integral part of their workday.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card">
                <h3>"We Trust Id You"</h3>
                <p>This term will be used often in the workplace. We consider this as a way to demonstrate our appreciation for one another's individuality and wisdom. Here at trust Id, we advocate for and encourage the virtues of gratitude, compassion, humility, and appreciation. We encourage one other's success and make it a point to showcase our collective accomplishments.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card">
                <h3>Curiosity and pride in one's own education</h3>
                <p>The groundbreaking Lottie file format drives Trust Id exciting initiative. Our crew is inquisitive, enthusiastic, and devoted to learning as we push motion and creativity frontiers. Our team values ownership as a vital trait.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card">
                <div>
                  <Lottie options={{ loop: true, autoplay: true, animationData: team3 }} height={300} width={300} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row g-4">

          <div className="col-lg-8 col-md-6 col-xs-12">
            <img src={team1} className="img-fluid gallery_img1" />
          </div>

          <div className="col-lg-4 col-md-6 col-xs-12">
            <img src={team2} className="img-fluid gallery_img2" />
          </div>

        </div>
      </div>

      <div className="benifit">
        <div className="container">
          <h2>Perks & Benefits</h2>
        </div>
        <div className="container">
          <div className="row sec3_card_m">

            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card2">
                <div>
                  <img src="https://framerusercontent.com/images/AWRt9VEcnYSssXE4clAvjvQRJM.svg" alt="Remote and flexible employment options" />
                </div>
                <h3>Remote and flexible employment options</h3>
                <p>Our culture encourages long-term productivity via a combination of dedicated work time and time off. Work from anywhere, set your own schedule, and take as much time off as you need to keep performing at a high level.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card2">
                <div>
                  <img src="https://framerusercontent.com/images/pi5SWt97ZLjhiyLEjzlpIqdj9E.svg" alt="Comfortable home office design" />
                </div>
                <h3>Comfortable home office design</h3>
                <p>We encourage a friendly and honest atmosphere. Our first priority is making the office a welcoming place where everyone feels comfortable sharing ideas and receiving constructive criticism.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card2">
                <div>
                  <img src="https://framerusercontent.com/images/siIa3wNzAteTKayr6MnehEWWxsE.svg" alt="Vacation time as required" />
                </div>
                <h3>Vacation time as required</h3>
                <p>You will hear this phrase often at work. We see this as a means to show how much we value each other's unique perspectives, experiences, and perspectives. Gratitude, compassion, humility, and appreciation are values that we actively promote here at Trust Id. We support each other's ascent and strive to highlight our combined achievements.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card2">
                <div>
                  <img src="https://framerusercontent.com/images/AWRt9VEcnYSssXE4clAvjvQRJM.svg" alt="An encouraging workplace" />
                </div>
                <h3>An encouraging workplace</h3>
                <p>You will hear this phrase often at work. We see this as a means to show how much we value each other's unique perspectives, experiences, and perspectives. Gratitude, compassion, humility, and appreciation are values that we actively promote here at Trust Id. We support each other's ascent and strive to highlight our combined achievements.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card2">
                <div>
                  <img src="https://framerusercontent.com/images/I8NvSnHhYnp3fq18oR1nlf1KHs.svg" alt="Unlimited learning budget" />
                </div>
                <h3>Unlimited learning budget</h3>
                <p>You will hear this phrase often at work. We see this as a means to show how much we value each other's unique perspectives, experiences, and perspectives. We make an effort to promote an environment of thanks giving, generosity, modesty, and admiration here at Trust Id. We support each other's ascent and strive to highlight our combined achievements.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card2">
                <div>
                  <img src="https://framerusercontent.com/images/SXvG2DdlOB1ztWQh3aibcSP6Z60.svg" alt="A competitive salary" />
                </div>
                <h3>A competitive salary</h3>
                <p>You will hear this phrase often at work. We see this as a means to show how much we value each other's unique perspectives, experiences, and perspectives. Gratitude, compassion, humility, and appreciation are values that we actively promote here at Trust Id. We support each other's ascent and strive to highlight our combined achievements.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="career_card2">
                <div>
                  <img src="https://framerusercontent.com/images/a2sPcpn8FDzwQRcrJpXC4kfv90g.svg" alt="Off-site Team Retreat" />
                </div>
                <h3>Off-site Team Retreat</h3>
                <p>This is a term that is used often here at our organisation. We make an effort to promote an environment of thankfulness, generosity, modesty, and respect here at Trust Id. Our shared goal is to raise each other up and make each other more successful.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="career_section5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="sec5_tittle">
                <h2>
                  Take a look at our 7-step interview process
                </h2>
                <button className="section5_button">
                  see how we hire <i className="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <div className="section5_img">
                <img src="https://framerusercontent.com/images/pdQryUHN1mEjSjlIk6J9PyXCs.png" alt="Interview process" className="secimg1" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="career2">
        <div className="container">
          <h2>Open Positions</h2>
          <ul>
            <li className="heading-underline">
              <p>React Native Developer</p>
              <a style={{ color: '#7e7e7e' }} href="#">Apply Now</a>
            </li>
          </ul>
          <ul>
            <li className="heading-underline">
              <p>Digital Marketing Executive</p>
              <a style={{ color: '#7e7e7e' }} href="#">Apply Now</a>
            </li>
          </ul>
          <ul>
            <li className="heading-underline">
              <p>React Js</p>
              <a style={{ color: '#7e7e7e' }} href="#">Apply Now</a>
            </li>
          </ul>
          <ul>
            <li className="heading-underline">
              <p>NodeJs Backend Developer</p>
              <a style={{ color: '#7e7e7e' }} href="#">Apply Now</a>
            </li>
          </ul>

          <ul>
            <li className="heading-underline">
              <p>Php Laravel</p>
              <a style={{ color: '#7e7e7e' }} href="#">Apply Now</a>
            </li>
          </ul>
          <ul>
            <li className="heading-underline">
              <p>Solidity Developer</p>
              <a style={{ color: '#7e7e7e' }} href="#">Apply Now</a>
            </li>
          </ul>
          <ul>
            <li className="heading-underline">
              <p>Rust Developer</p>
              <a style={{ color: '#7e7e7e' }} href="#">Apply Now</a>
            </li>
          </ul>
        </div>
      </div>
      <Footer/>
    </div>


  )
}

export default Career
