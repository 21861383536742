import React from 'react'
import "../Pricing.css"
import { Link } from 'react-router-dom'
import Topheader from './Topheader'
import Navbar from './Navbar'
import Footer from './Footer'
const Pricing = () => {
  return (
    <>
       <Topheader />
      <Navbar />
      <div className="container">
        <h1 class="text-center heading_second fs-8 mt-5  ">Pay only for <span class="Database">what you need</span></h1>
        <p class="text-center paratext1">Join dozens of high-growth startup</p>

        <div className="row mt-5 mb-5">
          <div className="col-lg-4">
            <div className="card pricecard border-0">
              <div className="pricecardhead">
                <h2 color='text-dark'>Identity Verification</h2>
                <h6 class="price">₹5</h6>
                <p class="sub">Verify Identity of of Your customer just one click.</p>
                <Link to="/payment" class="btn btnbuy fromcontral">Buy Credit <i class="fa-solid fa-arrow-right ms-2"></i></Link>
              </div>
            </div>
            {/* <div class="details">
      <div class="details-row">
     <div className="">
     <i class="fa-solid fa-check"></i>
     </div>
       <div className="">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum, eos dolore libero .</div>
      </div>
      <div class="details-row">
     <div className="">
     <i class="fa-solid fa-check"></i>
     </div>
       <div className="">10 user</div>
      </div>
    </div> */}

          </div>
          <div className="col-lg-4">
            <div className="card pricecard border-0">
              <div className="pricecardhead">
                <h2 className='text-primary'>Soundbox </h2>
                <div className="d-flex gap-2 ">
                  <p class="price  text-primary">₹2000</p>
                  <p className="sub  " style={{ fontSize: "14px" }}>per month,<br />per connectors</p>
                </div>
                <p class="sub">Verify legimacy of vistor without any hussle with Trustid box.</p>
                {/* <button type="button" class="btn btn-primary  rounded-pill">Buy this plan</button> */}
              </div>
            </div>
            {/* <div class="details">
      <div class="details-row">
     <div className="">
     <i class="fa-solid fa-check"></i>
     </div>
       <div className="">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum, eos dolore libero .</div>
      </div>
      <div class="details-row">
     <div className="">
     <i class="fa-solid fa-check"></i>
     </div>
       <div className="">10 user</div>
      </div>
    </div> */}

          </div>
          <div className="col-lg-4">
            <div className="card pricecard border-0 ">
              <div className="pricecardhead pricecard3">
                <h2 className='text-light'>DAAC Service</h2>
                <p class="price  text-light">Custom</p>
                <p class="sub">Door Access kit for those whose legitmacy verify through kyc.</p>
                {/* <button type="button" class="btn btn-dark custom-btn rounded-pill">Talk to sales</button> */}
              </div>
            </div>
            {/* <div class="details">
      <div class="details-row">
     <div className="">
     <i class="fa-solid fa-check"></i>
     </div>
       <div className="">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum, eos dolore libero .</div>
      </div>
      <div class="details-row">
     <div className="">
     <i class="fa-solid fa-check"></i>
     </div>
       <div className="">10 user</div>
      </div>
    </div> */}

          </div>
        </div>

      </div>
      <Footer/>
    </>
  )
}

export default Pricing