import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
    <div className="collabrators contact_bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="collbration_div">
              <s6 className="s_6">Looking for development or collaboration?</s6>
              <p className='footer_parademo'>
               
"Unlock the full potential of Trust Id and collective knowledge by requesting a price or contacting us today."
              </p>
              <div className="footer_banner">
                <a href="https://wa.me/+917985202681" aria-label="Whatsapp"><i className="fab fa-whatsapp"></i></a>
                <Link to="tel:+917985202681">
                <i className="fas fa-phone"></i>
                </Link>
                <a href="mailto:info@trustid.one" aria-label="Mail"><i className="fas fa-envelope"></i></a>
              </div>
             <Link to="/contact"> <button className='btn p-2'>Request for Demo</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer className="text-center text-lg-start  text-muted">
    
  
    <section className="">
      <div className="container text-center text-md-start mt-5">
        <div className="row mt-3">
          
  
          <div className="col-12 col-md-2 mx-auto mb-4">
          <h5 class="title-widget">Product</h5>
          <ul class="footer_list">
            <li><a href="/poa" className="text-reset">Point of Access (POA)</a></li>
            <li><a href="/soundbox" className="text-reset">Soundbox</a></li>
            <li><a href="/QrStandy" className="text-reset">Trust QR Standy</a></li>
            <li><a href="/dac" className="text-reset">Door Access Kit (DAC)</a></li>
            </ul>
           
          </div>
  
          <div className="col-12 col-md-2 mx-auto mb-4">
          <h5 class="title-widget">Industry</h5>
          <ul class="footer_list">
            <li>
              <a href="/finance" className="text-reset">Finance</a>
            </li>
            
            <li>
              <a href="/hospital" className="text-reset">Hospitality</a>
            </li>
            
            <li>
              <a href="/coprate" className="text-reset">Corporate</a>
            </li>
            <li>
              <a href="/e-commerce" className="text-reset">E-Commerce</a>
            </li>
            </ul>
          </div>

          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h5 class="title-widget">Service</h5>
          <ul class="footer_list">
            <li>
              <a href="/Kyc-Aml" className="text-reset">Kyc/Aml Verification</a>
            </li>
            <li>
              <a href="/ocl" className="text-reset">One Click Login (OCL)</a>
            </li>
            <li>
              <a href="/SecurityAccess" className="text-reset">Security Access</a>
            </li>
            <li>
              <a href="/onboard" className="text-reset">Onboard Screening </a>
            </li>
            </ul>
          </div>

          

          <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h5 class="title-widget">Contact Us</h5>
            <p>
            <div class="contact_button">
                             <i class="fa-solid fa-phone"></i>&nbsp;+91-8840490443
                               </div>
            </p>
            <p>
            <div class="contact_button1">
                            <i class="fa-solid fa-phone"></i>&nbsp;+91-9870635001
                               </div>
            </p>
            <p>
            <div class="contact_button2">
                            <i class="fa-solid fa-phone"></i>&nbsp;+91-9870635001
                               </div>
            </p>
          </div>
        </div>
        <div className="row">
            <div className="col-md-6 col-xs-12 col-sm-12">
                <div className="social_icon">
                    <a href="https://twitter.com/trstid" target="_blank" rel="noopener noreferrer" aria-label="Twitter"><i className="fa-brands fa-twitter additional-icon" style={{color:'#1c96e8'}}></i></a>
                    <a href="https://www.linkedin.com/company/trstid" target="_blank" rel="noopener noreferrer" aria-label="Linkedin"><i className="fa-brands fa-linkedin additional-icon" style={{color:'#007ab5'}}></i></a>
                </div>
            </div>
            <div className="col-md-6 col-xs-12 col-sm-12">
                <div className="region">
                    <div style={{ color: '#000' }}>
                        <i className="fa-solid fa-globe" style={{ color: '#000' }}></i>
                        &nbsp; Region:
                    </div>
                   
                    <div>
                        <p style={{ color: '#000' }}>India</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 col-xs-12 col-sm-12">
                <ul className="dta_xyz">
                    <li>
                        <a href="/contact">Contact Us |&nbsp;</a>
                        <a href="/privacy"> Privacy Policy |&nbsp;</a>
                        <a href="/terms"> Terms &amp; Conditions |&nbsp;</a>
                        <a href="/refund&Cancellation"> Refund & Cancellation |&nbsp;</a>
                        <a href="/Shipping&Delivery"> Shipping & Delivery |&nbsp;</a>
                        <a href="/"> Help&nbsp;</a>
                    </li>
                </ul>
            </div>
            <div className="col-md-4 col-xs-12 col-sm-12">
                <div className="copyryt_col text-nowrap">
                    <a href="https://blog.trustid.one/"><p>Blog</p></a>
                    <a href="/press"><p>Press</p></a>
                    <a href="meeting"><p>Meet the team</p></a>
                    <a href="/career"><p>careers</p></a>
                </div>
            </div>
            <div className="col-md-5 col-xs-12 col-sm-12">
                <h6 className="footer_cpy_ryt">Naygon Technologies Pvt Ltd</h6>
            </div>
            <div className="col-md-3 col-xs-12 col-sm-12">
                <p className="footer_madewith">Made with <i className="fa fa-heart" style={{ color: '#ff0000' }}></i> from India</p>
            </div>
        </div>
      </div>
    </section>

  </footer>
    </div>
  );
};

export default Footer;
