import React from "react";
import Topheader from "./Topheader";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Onboard = () => {
  return (
    <>
       <Topheader />
      <Navbar />
      <div className="privacy_and_heading">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <div className="text-center">
                <h1 className="">Onboard Screening</h1>
                <p>
                  Trust Id Onboard Screening: Streamlining Secure Onboarding
                  Processes 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy_and_policy_area">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <h5 className="privacy_head">Introduction</h5>
              <ul>
                <li>
                  Effective onboard screening is critical in establishing secure
                  and compliant operations within any organization. Trust Id's
                  Onboard Screening service provides comprehensive background
                  checks, identity verification, and risk assessment to ensure
                  that new hires or partners meet your organization's standards
                  for security and integrity. Importance of Thorough Onboard
                  Screening
                </li>
              </ul>
              <h5 className="privacy_head">Enhancing Workplace Security</h5>
              <ul>
                <li>
                  Thorough screening during the onboarding process helps prevent
                  potential security threats by ensuring that only trustworthy
                  individuals are integrated into your organization. Regulatory
                  Compliance
                </li>
                <li>
                  Many industries are required to perform detailed background
                  checks to comply with legal standards. Trust Id helps
                  streamline this compliance, reducing the risk of penalties and
                  enhancing public trust.
                </li>
              </ul>
              <h5 className="privacy_head">Building a Reliable Workforce</h5>
              <ul>
                <li>
                  Thorough screening during the onboarding process helps prevent
                  potential security threats by ensuring that only trustworthy
                  individuals are integrated into your organization. Regulatory
                  Compliance Onboard screening is essential in building a
                  competent and reliable workforce. It ensures that new team
                  members possess the credentials, background, and qualities
                  needed to contribute positively to your organization.
                </li>
                <li>
                  Features of Trust Id Onboard Screening Comprehensive
                  Background Checks
                </li>
                <li>
                  Trust Id conducts extensive checks covering criminal records,
                  employment history, educational qualifications, and reference
                  checks to provide a complete profile of the candidate.
                  Real-Time Identity Verification
                </li>
                <li>
                  Utilizing advanced technology, Trust Id offers real-time
                  identity verification to ensure the authenticity of documents
                  and identity claims made by prospective hires. Risk Assessment
                </li>
                <li>
                  Each screening includes a detailed risk assessment to identify
                  any potential issues that could impact your organization, from
                  security risks to compliance concerns. How Trust Id Onboard
                  Screening Works
                  <br />
                  <strong>Step 1:</strong> Integration and Setup
                </li>
                <li>
                  Trust Id's onboard screening services integrate seamlessly
                  with your existing HR systems, allowing for easy setup and
                  minimal disruption to your onboarding process. <br />
                  <strong>Step 2:</strong> Collecting Information
                </li>
                <li>
                  Prospective hires submit their personal and professional
                  information through a secure portal. Trust Id ensures the
                  privacy and security of all data collected during this
                  process. <br />
                  <strong>Step 3:</strong> Performing the Checks
                </li>
                <li>
                  Trust Id performs all necessary checks, utilizing a
                  combination of automated tools and expert analysis to ensure
                  thoroughness and accuracy. <br />
                  <strong> Step 4:</strong> Reporting and Decision Support
                </li>
                <li>
                  Detailed reports are generated, providing insights into each
                  candidate’s background and suitability for the role. Trust Id
                  also offers decision support to help you interpret the
                  findings and make informed hiring decisions. <br />
                  <strong>Step 5:</strong> Continuous Updates and Re-Screening
                </li>
                <li>
                  Trust Id provides options for continuous monitoring and
                  re-screening to ensure ongoing compliance and security as new
                  information arises or regulations change. Benefits of Using
                  Trust Id Onboard Screening
                </li>
              </ul>
              <h5 className="privacy_head">Reliable Hiring Decisions</h5>
              <ul>
                <li>
                  Make informed decisions with comprehensive background checks
                  and risk assessments provided by Trust Id.
                </li>
              </ul>
              <h5 className="privacy_head">Faster Onboarding</h5>
              <ul>
                <li>
                  Streamline your onboarding process with efficient and
                  automated screenings that reduce the time from recruitment to
                  hire.
                </li>
              </ul>
              <h5 className="privacy_head">
                Improved Compliance and Reduced Risk
              </h5>
              <ul>
                <li>
                  Stay compliant with industry regulations and reduce the risk
                  of hiring unsuitable candidates, which can lead to financial
                  and reputational damage.
                </li>
              </ul>
              <h5 className="privacy_head">
                Industries Benefiting from Trust Id Onboard Screening
              </h5>
              <ul>
                <li>
                  <strong>Financial Services:</strong> Ensure the integrity of
                  new hires in positions handling sensitive financial
                  information.
                </li>
                <li>
                  <strong>Healthcare: </strong>Confirm the credentials and
                  backgrounds of healthcare professionals to maintain patient
                  safety and care quality.
                </li>
                <li>
                  <strong>Education:</strong> Screen educational staff to
                  safeguard students and uphold educational standards.
                </li>
                <li>
                  <strong> Technology: </strong>Verify the qualifications and
                  security clearances of personnel in tech roles to protect
                  intellectual property and data.
                </li>
                <li>
                  <strong> Government and Public Sector:</strong> Ensure public
                  servants meet the strict standards required for government
                  positions.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Onboard;
