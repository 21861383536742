import React, { useState } from "react";
import { Link } from "react-router-dom";

function Features() {
  const [activeTab, setActiveTab] = useState("nav-home");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div>
      <section className="safety-section">
        <div className="container py-4">
          <div className="row gy-4 justify-content-center">
            <div className="col-12 text-center mb-4">
              <h2 className="mb-2 safe_heading">Host own KYC/AML </h2>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="safety-card text-center">
                <div className="moving-top safety-image mx-auto mb-4">
                  <img src="./assets/image/faqimg.png" className="img-fluid" />
                </div>
                <div className="safety-content">
                  <h4 className="mb-3 feature_heading">L2 Encryption</h4>
                  <p className="text-secondary feature_para">
                    Trust Id's L2 Encryption secures data with robust
                    cryptography, protecting identities and transactions with
                    top-tier privacy measures.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="safety-card text-center">
                <div className="moving-top safety-image mx-auto mb-4">
                  <img
                    src="./assets/image/local storage (3).png"
                    alt="Not Found"
                    className="img-fluid"
                  />
                </div>
                <div className="safety-content">
                  <h4 className="mb-3 feature_heading">Local Storage</h4>
                  <p className="text-secondary feature_para">
                    Trust Id uses Local Storage for secure, on-device data
                    preservation, ensuring quick access and enhanced user
                    privacy without compromising speed
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="safety-card text-center">
                <div className="moving-top safety-image mx-auto mb-4">
                  <img
                    src="./assets/image/faqimg.png"
                    alt="Not Found"
                    className="img-fluid"
                  />
                </div>
                <div className="safety-content">
                  <h4 className="mb-3 feature_heading">#Hashed empower</h4>
                  <p className="text-secondary feature_para">
                    Trust Id's #HashedEmpower leverages hashing for data
                    integrity, ensuring secure, tamper-proof storage and
                    verification of user information.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="safety-card text-center">
                <div className="moving-top safety-image mx-auto mb-4">
                  <img
                    src="./assets/image/transparent authenticate (2).png"
                    alt="Not Found"
                    className="img-fluid"
                  />
                </div>
                <div className="safety-content">
                  <h4 className="mb-3 feature_heading">
                    Transparent Authentication
                  </h4>
                  <p className="text-secondary feature_para">
                    Trust Id's Transparent Authentication ensures seamless,
                    visible security, allowing users to verify their identity
                    effortlessly without disrupting user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative">
        <div className="container">
          <div className="mx-auto col-lg-4">
            <div
              className="nav nav-pills mb-3 btnchange"
              id="nav-tab"
              role="tablist"
            >
              {activeTab !== "nav-home" && (
                <button
                  className="nav-link px-3"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected={activeTab === "nav-home"}
                  onClick={() => handleTabClick("nav-home")}
                >
                  Trust Id
                </button>
              )}
              {activeTab !== "nav-profile" && (
                <button
                  className="nav-link px-3"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected={activeTab === "nav-profile"}
                  onClick={() => handleTabClick("nav-profile")}
                >
                  Trust Id Business
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="">
          <div className="tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade ${
                activeTab === "nav-home" ? "show active" : ""
              }`}
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="d-flex payment-section"  id="downloadApp">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6"></div>
                    <div className="col-lg-6">
                      <div className="payment-text text-center text-sm-start tittle_before heightdow">
                        <h2 className="mb-3 dowload_heading">
                          Download the app and make your life more easy.
                        </h2>
                        <p className="mt-1">
                          Trust Id is dedicated to turning your digital
                          aspirations into tangible experiences.
                        </p>
                        <div className="row">
                          <div className="col-5">
                            <Link to="https://apps.apple.com/in/app/trust-id-fast-secure-access/id6479017851">
                              {" "}
                              <h1 className="appstorage_heading">
                                {" "}
                                <img
                                  src="./assets/image/App store.png"
                                  className="img-fluid googleplay_img"
                                />{" "}
                                App Store
                              </h1>
                            </Link>

                            <Link to="https://play.google.com/store/apps/details?id=com.trst.id">
                              {" "}
                              <h1 className="appstorage_heading">
                                {" "}
                                <img
                                  src="./assets/image/Google Play.png"
                                  className="img-fluid googleplay_img"
                                />{" "}
                                Google Play
                              </h1>
                            </Link>
                            <Link to="https://chromewebstore.google.com/detail/trust-id/jniimgeiabeiikkcikpdiinaplkidbli">
                              {" "}
                              <h1 className="appstorage_heading">
                                <img
                                  src="./assets/image/chrome.png"
                                  className="img-fluid googleplay_img"
                                />{" "}
                                Chrome
                              </h1>
                            </Link>
                          </div>

                          <div className="col-7">
                            <img
                              src="./assets/image/Arrow with qr.png"
                              alt="Not Found"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                activeTab === "nav-profile" ? "show active" : ""
              }`}
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div className="d-flex payment-section1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 "></div>
                    <div className="col-lg-6">
                      <div className="payment-text text-center text-sm-start tittle_before heightdow">
                        <h2 className="mb-3 dowload_heading">
                          Download the app and make your life more easy.
                        </h2>
                        <p className="mt-1">
                          Trust Id Business is dedicated to turning your digital
                          aspirations into tangible experiences.
                        </p>
                        <div className="row">
                          <div className="col-5">
                            <h1 className="appstorage_heading">
                              {" "}
                              <img
                                src="./assets/image/App store.png"
                                className="img-fluid googleplay_img"
                              />{" "}
                              App Storage
                            </h1>

                            <h1 className="appstorage_heading">
                              {" "}
                              <img
                                src="./assets/image/Google Play.png"
                                className="img-fluid googleplay_img"
                              />{" "}
                              Google Play
                            </h1>
                            <h1 className="appstorage_heading">
                              <img
                                src="./assets/image/Android.png"
                                className="img-fluid googleplay_img"
                              />{" "}
                              Android APK
                            </h1>
                          </div>

                          <div className="col-7">
                            <img
                              src="./assets/image/Arrow with qr.png"
                              alt="Not Found"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;
