import React from "react";
import Topheader from "./Topheader";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Hospital = () => {
  return (
    <>
       <Topheader />
      <Navbar />
      <div className="privacy_and_heading">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <div className="text-center">
                <h1 className="">Hospital</h1>
                {/* <p>
                  Trust Id:Revolutionizing KYC Verification in the Finance
                  Industry
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy_and_policy_area">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <h5 className="privacy_head">Introduction</h5>

              <ul>
                <li>
                  Enhancing Guest Experience with Trust Id in the Hospitality
                  Industry
                </li>
                <li>
                  In the hospitality industry, creating a seamless and
                  personalized guest experience is paramount. Trust Id
                  introduces an innovative KYC verification system that
                  simplifies guest check-ins, enhances security, and streamlines
                  payment processes, all while ensuring compliance with
                  regulatory requirements.
                </li>
              </ul>
              <h5 className="privacy_head">Importance of KYC</h5>

              <ul>
                <li>
                  The Importance of KYC in Hospitality Ensuring Guest Safety
                </li>
                <li>
                  KYC processes in hospitality are crucial for the safety and
                  security of guests and property. By verifying the identities
                  of guests, hotels and resorts can prevent unauthorized access
                  and reduce the risk of fraudulent activities. Regulatory
                  Adherence
                </li>
                <li>
                  Similar to the finance sector, the hospitality industry faces
                  its own set of regulatory challenges, especially concerning
                  guest data protection and privacy. Effective KYC practices
                  help establishments remain compliant with local and
                  international laws. Trust Id's Role in Revolutionizing
                  Hospitality Services Quick and Efficient Guest Onboarding
                </li>
                <li>
                  Trust Id's QR code-based KYC verification allows for instant
                  identity confirmation, which significantly speeds up the
                  check-in process, reducing wait times and improving guest
                  satisfaction. Secure Data Handling
                </li>
                <li>
                  By storing KYC data off-chain, Trust Id ensures that all
                  personal information is protected against data breaches,
                  providing guests with peace of mind regarding their privacy.
                  Integrated Payment Solutions
                </li>
                <li>
                  Trust Id facilitates a smooth payment process by integrating
                  wallet transactions directly into the platform. This means
                  that charges for services like room upgrades, amenities, and
                  dining can be handled quickly and securely, enhancing the
                  overall guest experience. Benefits of Implementing Trust Id in
                  Hospitality Streamlined Operations
                </li>
                <li>
                  Automating the KYC process reduces the administrative burden
                  on staff, allowing them to focus more on providing excellent
                  customer service rather than handling paperwork and data
                  entry. Improved Guest Trust and Loyalty
                </li>
                <li>
                  A swift and secure check-in process, coupled with robust data
                  security measures, builds trust among guests. This trust
                  translates into higher satisfaction and loyalty, which are
                  critical for the success of any hospitality business. Enhanced
                  Security
                </li>
                <li>
                  Trust Id's advanced verification system ensures that only
                  verified individuals can access certain services and
                  facilities, thus maintaining a safe environment for all
                  guests. How Trust Id Transforms Guest Experiences: A
                  Step-by-Step Overview <br />
                  <strong>Step 1:</strong> Pre-Arrival Registration
                </li>
                <li>
                  Upon arrival, guests simply scan their QR code at the
                  reception. Their identity is verified instantly, allowing for
                  a swift and hassle-free check-in. <br />
                  <strong> Step 2:</strong> QR Code Verification at Check-In
                </li>
                <li>
                  Upon arrival, guests simply scan their QR code at the
                  reception. Their identity is verified instantly, allowing for
                  a swift and hassle-free check-in.
                  <br />
                  <strong> Step 3:</strong> On-Demand Services
                </li>
                <li>
                  Throughout their stay, guests can use their verified Trust Id
                  for easy access to various services and amenities, from spa
                  reservations to dining payments. <br />
                  <strong> Step 4:</strong> Secure Check-Out
                </li>
                <li>
                  At the end of their stay, guests can check out through a quick
                  QR scan, ensuring all services are billed correctly and
                  payments are processed without errors. <br />
                  <strong> Step 5:</strong> Compliance and Feedback
                </li>
                <li>
                  All transactions and interactions are securely logged, aiding
                  in compliance and providing valuable insights for service
                  improvement. The Future of Hospitality with Trust Id
                </li>
                <li>
                  As the hospitality industry evolves, Trust Id stands at the
                  forefront, driving innovation in guest management and service
                  delivery. With ongoing enhancements in technology, Trust Id is
                  set to offer even more features that cater to the dynamic
                  needs of the hospitality sector.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Hospital;
