import React, { useEffect, useState } from "react";
import Frame12 from "../components/image/bio.png";
import Frame13 from "../components/image/docomentauth.png";
import Frame14 from "../components/image/asset1.png";
import Frame15 from "../components/image/ageverfication.png";
import Frame16 from "../components/image/fraud.png";
import Frame18 from "../components/image/secure.png";
import Frame19 from "../components/image/qr code.png";
import Frame110 from "../components/image/multi factor.png";
import Frame111 from "../components/image/digital sign.png";
import Frame112 from "../components/image/data privacy.png";

function Services() {
  const [mobile, setMobile] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const numCardsToShow = mobile ? 1 : 3;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const checkMobile = () => {
    const isMobile = window.innerWidth <= 480; // Adjust as per your breakpoint
    setMobile(isMobile);
  };

  // Check if mobile on component mount and resize
  useEffect(() => {
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const services = [
    {
      card_bg: "service_card1",
      mb_2: "mb-2",
      image: Frame12,
      title: "Biometric Verification",
      description:
        " Utilizing facial recognition and fingerprint scanning for  secure and instant identity verification.\n \n\n\n \n \n\n\n",
    },
    {
      card_bg: "service_card2",
      image: Frame13,
      title: "Document Authentication",
      description:
        "Verifying the authenticity of government-issued IDs, passports, and other vital documents.",
    },
    {
      card_bg: "service_card3",
      image: Frame14,
      title: "Real-Time KYC Compliance",
      description:
        "Offering businesses a streamlined way to fulfill Know Your Customer regulations quickly and efficiently.",
    },
    {
      card_bg: "service_card1",
      image: Frame15,
      title: "Age Verification",
      description:
        "Ensuring age-restricted services are accessed only by eligible users through accurate age verification processes.",
    },
    {
      card_bg: "service_card2",
      image: Frame16,
      title: "Fraud Prevention",
      description:
        "Leveraging advanced analytics to detect and prevent fraudulent activities, enhancing the security of online transactions.",
    },

    {
      card_bg: "service_card1",
      image: Frame18,
      title: "Secure Online Onboarding",
      description:
        "Facilitating a smooth and secure digital onboarding experience for customers across various platforms.",
    },
    {
      card_bg: "service_card2",
      image: Frame19,
      title: "QR Code Access Control",
      description:
        "Providing secure, contactless entry to buildings and systems via encrypted QR codes.",
    },
    {
      card_bg: "service_card3",
      image: Frame110,
      title: "Multi-Factor Authentication",
      description:
        "Ensuring age-restricted services are accessed only by eligible users through accurate age verification processes.",
    },
    {
      card_bg: "service_card1",
      image: Frame111,
      title: " Digital Signature Authentication",
      description:
        "Ensuring age-restricted services are accessed only by eligible users through accurate age verification processes.",
    },
    {
      card_bg: "service_card2",
      image: Frame112,
      title: "Data Privacy Management",
      description:
        "Ensuring age-restricted services are accessed only by eligible users through accurate age verification processes.",
    },
  ];

  return (
    <section className="py-5">
      <div className="container text-center">
        <div className="row align-items-end">
          <div className="col-12 col-md-6">
            <h5 className="text-start text_center">
              <span class="title_line"></span>Services
            </h5>
            <h1
              className="text-start text_center"
              style={{ fontWeight: "600" }}
            >
              Transforming Digital Visions into Reality
            </h1>
          </div>
          <div className="col-12 col-md-6 text-end text_center">
            {/* <button
              className="circle_btn me-3 mobliehidebtn"
              onClick={handlePrev}
              disabled={currentIndex === 0}
            >
              <i class="fa-solid fa-less-than"></i>
            </button> */}
            <button
              className="circle_btn "
              onClick={handleNext}
              disabled={currentIndex >= services.length - numCardsToShow}
            >
              <i class="fa-solid fa-greater-than"></i>
            </button>
          </div>
        </div>

                <div className='row mt-4 g-4'>
                    {services.slice(currentIndex, currentIndex + numCardsToShow).map((service, index) => (
                        <div key={index} className='col-12 col-md-4'>
                            <div className={`card ${service.card_bg}`}>
                                <div className='card-body text-start p-4'>
                                    <img src={service.image} style={{ width: '100px' }} alt={service.title} />
                                    <h5 className='server_heading'>{service.title}</h5>
                                    <p className='server_para'>{service.description}</p>
                                    <p className={service.mb_2}>Read more <i className="fa-solid fa-arrow-right"></i></p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='row mt-4'>
                    <div className='col-12 col-md-12'>
                        <a href='#' className='btn home_bt px-5' style={{ borderRadius: '25px' }}>See All</a>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default Services;
