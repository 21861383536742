import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function PaymentApp() {
    const location = useLocation();
    const [phone, setPhone] = useState("");
    const [amount, setAmount] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [pay, setpay] = useState("Payment");

    useEffect(() => {
        function getParameterByName(name, url) {
            name = name.replace(/[\[\]]/g, "\\$&");
            const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
            const results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }
        const amount = getParameterByName('amount', location.search);
        const phone = getParameterByName('phone', location.search);

        setPhone(phone)
        setAmount(amount)
        const currentDate = new Date();


        const options = {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        };
        const formattedDateTime = currentDate.toLocaleDateString('en-US', options);

        setCurrentTime(formattedDateTime)
    }, [location.search]);
    function generateRandomString() {
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var randomString = '';
        for (var i = 0; i < 6; i++) {
            randomString += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return randomString;
    }

    // Example usage:
    console.log(generateRandomString(), pay);

    return (
        <div className="container mt-1">
            <div className="row justify-content-center align-items-center   pay-card">

                <div className="message-box _success _failed">

                    <div className="invdoice-card">
                        <form method="POST" action="https://trst.ink/api/pay">
                            <div className="invoice-title">
                                <div id="main-title">
                                    <input
                                        type="text"
                                        name="Payment"
                                        style={{ border: 'none', background: "#fff" }}
                                        // disabled
                                        value={pay}

                                    />
                                    <span>#{generateRandomString()}</span>
                                </div>

                                <span id="date">{currentTime}</span>
                            </div>

                            <div className="invoice-details">
                                <table className="invoice-table">
                                    <thead>
                                        <tr>
                                            <td>Info</td>
                                            <td>Details</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr className="row-data">
                                            <td>Mobile</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="phone"
                                                    dir="rtl"
                                                    style={{ border: 'none', background: "#fff" }}
                                                    value={phone}
                                                // disabled
                                                />
                                            </td>
                                        </tr>

                                        <tr className="row-data">
                                            <td>Amount </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    name="amount"
                                                    style={{ border: 'none', background: "#fff" }}
                                                    // disabled
                                                    value={amount}
                                                    dir="rtl"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="invoice-footer">
                                <button type="submit" className="btn-pay btn-primary fw-bold">PAY NOW &#8594;</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>


    );
}

export default PaymentApp;
