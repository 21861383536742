import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./index.css";
import "./App.css";
import "./components/custom.css";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Press from "./components/Press";
import Career from "./components/Career";
import Meeting from "./components/Meeting";
import Industries from "./components/Industries";
import Resources from "./components/Resources";
import Company from "./components/Company";
import Product from "./components/Product";
import Refundcancellation from "./components/Refund&cancellation";
import Shippingdelivery from "./components/Shipping&delivery";
import Pricing from "./components/Pricing";
import FinacePage from "./components/FinacePage";
import Hospital from "./components/Hospital";
import Coprate from "./components/Coprate";
import QrStandy from "./components/QrStandy";
import SecurityAccess2 from "./components/SecurityAccess2";
import ServiceSection from "./components/ServiceSection";
import SoundboxTrust from "./components/SoundboxTrust";
import Ocl from "./components/Ocl";
import Onboard from "./components/Onboard";
import DAAC from "./components/DAAC";
import Ecommerce from "./components/Ecommerce";
import Poa from "./components/Poa";
import Payment from "./components/payment";
import Successfully from "./components/successfully";
import Payment_failed from "./components/Payment_failed";
import PaymentApp from "./components/PaymentApp";
import AppPaymentSuccess from "./components/AppPaymentSuccess";
import AppPaymentFailure from "./components/AppPaymentFailure";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <Router>
    
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/product" element={<Product />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/press" element={<Press />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/meeting" element={<Meeting />} />
        <Route exact path="/industries" element={<Industries />} />
        <Route exact path="/about" element={<Resources />} />
        <Route exact path="/company" element={<Company />} />
        <Route exact path="/payment" element={<Payment />} />
        <Route exact path="/successfully" element={<Successfully />} />
        <Route exact path="/payment_failed" element={<Payment_failed />} />
        <Route exact path="/paymentApp" element={<PaymentApp />} />
        <Route exact path="/AppPaymentSuccess" element={<AppPaymentSuccess />} />
        <Route exact path="/AppPaymentFailure" element={<AppPaymentFailure />} />

        <Route
          exact
          path="/refund&Cancellation"
          element={<Refundcancellation />}
        ></Route>
        <Route
          exact
          path="/Shipping&Delivery"
          element={<Shippingdelivery />}
        ></Route>
        <Route exact path="/Pricing" element={<Pricing />}></Route>
        <Route exact path="/finance" element={<FinacePage />}></Route>
        <Route exact path="/hospital" element={<Hospital />}></Route>
        <Route exact path="/coprate" element={<Coprate />}></Route>
        <Route exact path="/QrStandy" element={<QrStandy />}></Route>
        <Route
          exact
          path="/SecurityAccess"
          element={<SecurityAccess2 />}
        ></Route>
        <Route exact path="/Kyc-Aml" element={<ServiceSection />}></Route>
        <Route exact path="/soundbox" element={<SoundboxTrust />}></Route>
        <Route exact path="/ocl" element={<Ocl />}></Route>
        <Route exact path="/onboard" element={<Onboard />}></Route>
        <Route exact path="/dac" element={<DAAC />}></Route>
        <Route exact path="/e-commerce" element={<Ecommerce />}></Route>
        <Route exact path="/poa" element={<Poa />}></Route>
      </Routes>
    
    </Router>
  </StrictMode>,
  rootElement
);
