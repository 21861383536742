import React, { useEffect, useState } from "react";
import "../Pricing.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Topheader from "./Topheader";
import Navbar from "./Navbar";
import Footer from "./Footer";

function Successfully() {

  const location = useLocation();
  const[amount,setAmount] = useState(0)
  const[transactionId,setTransactionId] = useState('')
  const[date,setDate] = useState('')

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const trxnid = searchParams.get('trxnid');
    const amount = searchParams.get('amount');
    setTransactionId(trxnid)
    setAmount(amount)
    console.log('trxnid:', trxnid);
    console.log('amount:', amount);
    const currentDate = new Date();
    const options = { 
      month: 'long', 
      day: 'numeric', 
      year: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric',
      hour12: true 
    };
    const formattedDateTime = currentDate.toLocaleDateString('en-US', options);
    setDate(formattedDateTime)
  }, [location.search]);

  return (
    <div>
         <Topheader/>
      <Navbar />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="message-box _success">
              <i
                className="fa fa-check-circle icon-shake"
                aria-hidden="true"
              ></i>
              <h2>Payment Successful</h2>
              <p>Thank you for your payment!</p>
              <p>
                <strong>Transaction ID:</strong>{transactionId}
              </p>
              <p>
                <strong>Amount Paid:</strong> Rs. {amount}
              </p>
              <p>
                <strong>Payment Date:</strong> {date}
              </p>
              {/* <div class="confirmation-footer">
              <a href="/payment" className="retry-link">Return to Home</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Successfully;
