import React, { useState } from 'react';

function Faq() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (accordionId) => {
    setActiveAccordion(accordionId === activeAccordion ? null : accordionId);
  };

  return (
    <div className="bgcolor py-3">
    <div className="container mb-3">

    <div class="faqtittle">
         <div class="text-style-cap">learn more</div>
         <h2>Integration and Installation FAQs</h2>
      </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-md-8 col-sm-6 review_block contact_block mb-5">
    <div className="accordion w-100" id="basicAccordion">
      <style>
        {`
          .accordion-item {
            border-top: 1px solid #f0f2f4;
    border-left: 1px solid #f0f2f4;
    border-right: 1px solid #f0f2f4;
}
          }
          .accordion-item:last-child {
            border-bottom: 1px solid #dee2e6;
          }
          .accordion-button {
    background-color: #f0f2f4;
    }
    .accordion-button:focus {
    border-color: #f0f2f4;
    box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #f0f2f4;
}
.accordion-item {
    color: #000;
    background-color: #f0f2f4;
}
        `}
      </style>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={`accordion-button ${activeAccordion === 'basicAccordionCollapseOne' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => toggleAccordion('basicAccordionCollapseOne')}
            aria-expanded={activeAccordion === 'basicAccordionCollapseOne' ? 'true' : 'false'}
            aria-controls="basicAccordionCollapseOne"
          >How can I integrate Trust Id into my business website or app for one-click login and KYC/AML identity verification?
          </button>
        </h2>
        <div
          id="basicAccordionCollapseOne"
          className={`accordion-collapse collapse ${activeAccordion === 'basicAccordionCollapseOne' ? 'show' : ''}`}
          aria-labelledby="headingOne"
          data-parent="#basicAccordion"
        >
          <div className="accordion-body">
          Integrating Trust Id into your website or app is a straightforward process. Start by signing up for a Trust Id business account. Once registered, you'll receive access to our API documentation and SDKs tailored for various platforms. Follow the step-by-step instructions to integrate our API into your system, enabling one-click login and KYC/AML verification features. Our support team is available to assist you with integration queries or challenges you might face during the setup.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingTwo">
          <button
            className={`accordion-button ${activeAccordion === 'basicAccordionCollapseTwo' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => toggleAccordion('basicAccordionCollapseTwo')}
            aria-expanded={activeAccordion === 'basicAccordionCollapseTwo' ? 'true' : 'false'}
            aria-controls="basicAccordionCollapseTwo"
          >
            What are the requirements for installing the TrustBox for audible identity verification in my business premises?
          </button>
        </h2>
        <div
          id="basicAccordionCollapseTwo"
          className={`accordion-collapse collapse ${activeAccordion === 'basicAccordionCollapseTwo' ? 'show' : ''}`}
          aria-labelledby="headingTwo"
          data-parent="#basicAccordion"
        >
          <div className="accordion-body">
          To install the TrustBox, ensure you have a stable internet connection and a power source to connect the device. The TrustBox should be placed in an area where it's easily audible to the person verifying their identity. Setup involves connecting the TrustBox to your existing Trust Id system through our provided interface, allowing it to communicate verification results audibly. Detailed installation guides are provided, and our technical support team is ready to assist with any installation questions.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button
            className={`accordion-button ${activeAccordion === 'basicAccordionCollapseThree' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => toggleAccordion('basicAccordionCollapseThree')}
            aria-expanded={activeAccordion === 'basicAccordionCollapseThree' ? 'true' : 'false'}
            aria-controls="basicAccordionCollapseThree"
          >
             Can Trust Id’s Door Access and QR Standy solutions be used in all types of businesses?
          </button>
        </h2>
        <div
          id="basicAccordionCollapseThree"
          className={`accordion-collapse collapse ${activeAccordion === 'basicAccordionCollapseThree' ? 'show' : ''}`}
          aria-labelledby="headingThree"
          data-parent="#basicAccordion"
        >
          <div className="accordion-body">
          Absolutely. Trust Id’s Door Access and QR Standy solutions are designed to cater to a wide range of business needs, from small retail shops to large corporate offices. Our solutions offer customizable access control and verification processes that can be tailored to your specific security requirements. Whether you need to control entry to a restricted area or provide a quick and secure verification point, our products are adaptable to your business environment.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingfour">
          <button
            className={`accordion-button ${activeAccordion === 'basicAccordionCollapsefour' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => toggleAccordion('basicAccordionCollapsefour')}
            aria-expanded={activeAccordion === 'basicAccordionCollapsefour' ? 'true' : 'false'}
            aria-controls="basicAccordionCollapsefour"
          >
            Are there any special technical skills required for the integration and installation of Trust Id products?
          </button>
        </h2>
        <div
          id="basicAccordionCollapsefour"
          className={`accordion-collapse collapse ${activeAccordion === 'basicAccordionCollapsefour' ? 'show' : ''}`}
          aria-labelledby="headingfour"
          data-parent="#basicAccordion"
        >
          <div className="accordion-body">
          Basic technical knowledge is sufficient for the integration and installation of most Trust Id products. Our integration APIs and installation guides are designed to be user-friendly, allowing your IT team to handle the setup with minimal hassle. For more complex scenarios or specific customizations, our technical support team is on hand to guide you through the process or answer any technical queries you might have.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingfive">
          <button
            className={`accordion-button ${activeAccordion === 'basicAccordionCollapsefive' ? '' : 'collapsed'}`}
            type="button"
            onClick={() => toggleAccordion('basicAccordionCollapsefive')}
            aria-expanded={activeAccordion === 'basicAccordionCollapsefive' ? 'true' : 'false'}
            aria-controls="basicAccordionCollapsefive"
          >
          How does Trust Id ensure the security of data during the integration and installation process?
          </button>
        </h2>
        <div
          id="basicAccordionCollapsefive"
          className={`accordion-collapse collapse ${activeAccordion === 'basicAccordionCollapsefive' ? 'show' : ''}`}
          aria-labelledby="headingfive"
          data-parent="#basicAccordion"
        >
          <div className="accordion-body">
          Security is at the core of everything we do at Trust Id. During both the integration and installation processes, we employ industry-standard encryption and secure communication protocols to protect data transmission. Our systems are designed to comply with global data protection regulations, ensuring that your and your users' data remains secure and private. Additionally, we regularly update our security measures to guard against emerging threats and vulnerabilities, ensuring your Trust Id setup remains secure at all times.
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  );
}

export default Faq;
