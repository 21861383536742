import React from "react";
import Topheader from "./Topheader";
import Navbar from "./Navbar";
import Footer from "./Footer";

const ServiceSection = () => {
  return (
    <>
       <Topheader />
      <Navbar />
      <div className="privacy_and_heading">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <div className="text-center">
                <h1 className="">Kyc/Aml Verification</h1>
                <p>KYC/AML Verification Services by Trust Id</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy_and_policy_area">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <h5 className="privacy_head">Introduction</h5>
              <ul>
                <li>
                  In today’s regulatory environment, robust Know Your Customer
                  (KYC) and Anti-Money Laundering (AML) practices are more than
                  compliance requirements—they are essential components of a
                  secure and trustworthy business framework. Trust Id offers
                  comprehensive KYC/AML verification services that streamline
                  these processes, ensuring efficiency, compliance, and
                  security. Why KYC/AML is Crucial for Your Business
                </li>
              </ul>
              <h5 className="privacy_head">Regulatory Compliance</h5>
              <ul>
                <li>
                  KYC and AML regulations are designed to prevent identity
                  theft, financial fraud, and money laundering. Compliance with
                  these regulations is mandatory for most industries,
                  particularly in sectors like finance, real estate, and gaming.
                  Trust Id ensures that your business meets these regulatory
                  requirements without any hassle.
                </li>
              </ul>
              <h5 className="privacy_head">Risk Management</h5>
              <ul>
                <li>
                  Effective KYC/AML processes help mitigate risks by ensuring
                  that the entities your business deals with are legitimate and
                  not part of any illegal activities. This is crucial in
                  safeguarding your operations and maintaining business
                  integrity.
                </li>
              </ul>
              <h5 className="privacy_head">Building Trust</h5>
              <ul>
                <li>
                  Customers and partners trust businesses that take security
                  seriously. By implementing thorough KYC/AML measures, you
                  demonstrate your commitment to safeguarding client information
                  and transactions, thereby enhancing your business's
                  reputation. Our KYC/AML Verification Services
                </li>
              </ul>
              <h5 className="privacy_head">Comprehensive Identity Checks</h5>
              <ul>
                <li>
                  Trust Id performs detailed verification of customer
                  identities, utilizing state-of-the-art technology to
                  authenticate documents and personal data against global
                  databases. This includes checks against government-issued IDs,
                  passports, and utility bills.
                </li>
              </ul>
              <h5 className="privacy_head">
                Continuous Monitoring and Updates
              </h5>
              <ul>
                <li>
                  AML regulations require ongoing monitoring of customer
                  activities to detect and report suspicious transactions. Trust
                  Id offers continuous monitoring services, ensuring that your
                  business remains compliant over time and adapts to changes in
                  regulatory requirements.
                </li>
              </ul>
              <h5 className="privacy_head">Enhanced Due Diligence</h5>
              <ul>
                <li>
                  For high-risk customers or complex business arrangements,
                  Trust Id provides enhanced due diligence services. This
                  involves deeper investigative processes to ensure
                  comprehensive understanding and mitigation of potential risks.
                  Features of Trust Id’s KYC/AML Services
                </li>
              </ul>
              <h5 className="privacy_head">
                Instant Verification with QR Codes
              </h5>
              <ul>
                <li>
                  Utilizing QR code technology, Trust Id can instantly verify
                  identities at any point of interaction, significantly reducing
                  the time and resources spent on manual verification processes.
                  Off-Chain Data Security
                </li>
                <li>
                  All personal and transactional data is stored off-chain,
                  providing an extra layer of security against cyber threats and
                  ensuring compliance with data protection laws. Seamless
                  Integration
                </li>
                <li>
                  Trust Id’s KYC/AML services can be seamlessly integrated into
                  your existing platforms with minimal disruption to operations,
                  providing a smooth transition and user experience. Industries
                  We Serve
                </li>
                <li>
                  <strong> Finance and Banking:</strong> Streamline customer
                  onboarding, perform continuous monitoring, and comply with
                  stringent financial regulations.
                </li>
                <li>
                  <strong>Real Estate: </strong> Verify identities during
                  property transactions and comply with anti-money laundering
                  directives.
                </li>
                <li>
                  <strong> Gaming and Gambling:</strong> Ensure compliance with
                  gaming regulations and prevent underage gambling and fraud.
                </li>
                <li>
                  <strong> Healthcare:</strong> Protect patient data and ensure
                  that healthcare providers meet industry compliance standards.
                </li>
                <li>
                  <strong>Retail and E-commerce:</strong> Enhance customer trust
                  and prevent fraudulent transactions in online and offline
                  retail environments.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default ServiceSection;
