import React, { useState } from 'react';
import Lottie from 'react-lottie';
import carrerimg from '../components/image/Animation - 1707301173222.json';
import team1 from '../components/image/team_img1.jpg'
import team2 from '../components/image/team_img2.jpg'
import foundername from '../components/image/aman_vaths_foun(1).png'
import flag from '../components/image/flag (1).svg'
import team3 from '../components/image/team_img3.jpg'
import Topheader from './Topheader';
import Navbar from './Navbar';
import Footer from './Footer';



function Meeting() {

  const [activeTab, setActiveTab] = useState('all-items');

  const handleTabClick = (filter) => {
    setActiveTab(filter);
  };

  return (
    <div>
      <Topheader />
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-6 col-xs-6 bg1-div">
            <h2 className="txt4">Trust Id</h2>
          </div>
          <div className="col-lg-7 col-md-6 col-xs-6 bg2-div"></div>
        </div>
      </div>
      <section className="py-5 my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-6 col-xs-12">
              <h1 className="about_head">Building the future of <br />Trust Id</h1>
              <p>
                "At Trust Id, a dedicated team of visionary experts collaborates to shape the future of Trust Id <br /> leveraging innovation and technology. They strive to redefine boundaries, fostering a world where Trust Id systems are integral to progress and empowerment."
                <br />
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <div className="team_sec1_img">
                <Lottie options={{ loop: true, autoplay: true, animationData: carrerimg }} height={310} width={310} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="team_sec2">
        <div className="container">
          <div className="row g-4">

            <div className="col-lg-8 col-md-6 col-xs-12">
              <img src={team1} className="img-fluid gallery_img1" alt="Team Member 1" />
            </div>
            <div className="col-lg-4 col-md-6 col-xs-12">
              <img src={team2} className="img-fluid gallery_img2" alt="Team Member 1" />
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <img src={team3} className="img-fluid gallery_img3" alt="Team Member 1" />
            </div>
            <div className="col-lg-6 col-md-6 col-xs-12">
              <img src='https://framerusercontent.com/images/by8B04LvdXqbMazPKLiZRwOWcGU.jpg' className="img-fluid gallery_img4" alt="Team Member 1" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div style={{ textAlign: 'center', fontSize: '32px', fontWeight: '600' }}>Mr. Founder</div>
        <div className="founder_sec p-md-5 p-3">
          <div className="card cardbg1">
            <div className="row g-4">
              <div className="col-lg-4 col-md-6 col-xs-12">
                <div className="founder_img">

                  <img src={foundername} className="foun_im" alt="img" />
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-xs-12">
                <div className="foundd">
                  <h3>Aman Vaths</h3>
                  <p style={{ fontWeight: 'bold' }}></p>
                  <p>"At Trust ID, a dedicated team of visionary experts collaborates to sculpt the future of digital empowerment.<br /> Leveraging innovation and technology, they strive to redefine boundaries, fostering a world where systems are integral to progress and empowerment."</p>
                  <div className="team_mem_link">
                    <a href="#"><i className="fab fa-twitter additional-icon"></i> </a>
                    <a href="#"><i className="fab fa-linkedin additional-icon"></i></a>
                    <a href="#"><i className="fab fa-facebook additional-icon"></i></a>
                    <a href="#"><i className="fab fa-instagram additional-icon"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="the_team">
        <div className="container">
          <div>
            <h2>Meet our team</h2>
          </div>
          <div>
            <ul className="team_pill_tab">
              <li className={`nav_item_li ${activeTab === 'all-items' ? 'active' : ''}`} onClick={() => handleTabClick('all-items')} data-filter="all-items">
                All
              </li>
              <li className={`nav_item_li ${activeTab === 'item1' ? 'active' : ''}`} onClick={() => handleTabClick('item1')} data-filter="item1">
                Technology
              </li>
              <li className={`nav_item_li ${activeTab === 'item2' ? 'active' : ''}`} onClick={() => handleTabClick('item2')} data-filter="item2">
                Design
              </li>
              <li className={`nav_item_li ${activeTab === 'item3' ? 'active' : ''}`} onClick={() => handleTabClick('item3')} data-filter="item3">
                Marketing
              </li>
              <li className={`nav_item_li ${activeTab === 'item4' ? 'active' : ''}`} onClick={() => handleTabClick('item4')} data-filter="item4">
                Leadership
              </li>
              <li className={`nav_item_li ${activeTab === 'item5' ? 'active' : ''}`} onClick={() => handleTabClick('item5')} data-filter="item5">
                Operation & Support
              </li>
              <li className={`nav_item_li ${activeTab === 'item6' ? 'active' : ''}`} onClick={() => handleTabClick('item6')} data-filter="item6">
                Product
              </li>
              <li className={`nav_item_li ${activeTab === 'item7' ? 'active' : ''}`} onClick={() => handleTabClick('item7')} data-filter="item7">
                Support
              </li>
            </ul>

            <div className="container">
              <div className="_abt_team_setion">

                <div id="sections">
                  {activeTab === 'all-items' && (
                    <section>
                      <div class="container">
                        <div className="abt_team_section">

                          <div className="about_team_mem item4">
                            <div class="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/amanvaths.png" alt="Aman's Image" className='teamimg' />
                            </div>
                            <div class="title_abt_team">
                              <p class="team_mem_name">Aman<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p class="team_mem_designation">CTO</p>
                              <div class="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/naman.png" alt="Naman's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Naman<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">CEO</p>
                              <div className="team_mem_link">
                                <i className="fab fa-twitter additional-icon"></i>
                                <i className="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/wazid.png" alt="Wazid's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Wazid<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Director</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Simran.png" alt="Simran's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Simran<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Hr Manager</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Deshraj.png" alt="Deshraj's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Deshraj<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Senior Developer</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/viabhav-modified.png" alt="Prince's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Prince<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Graphic Designer</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>




                        </div>
                        <div className="abt_team_section mt-5">

                          <div className="about_team_mem item4">
                            <div class="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Akash.png" alt="Akash's Image" className='teamimg' />
                            </div>
                            <div class="title_abt_team">
                              <p class="team_mem_name">Akash<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p class="team_mem_designation">Designer</p>
                              <div class="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Swati .png" alt="Swati's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Swati<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">UI/UX Designer</p>
                              <div className="team_mem_link">
                                <i className="fab fa-twitter additional-icon"></i>
                                <i className="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Shivanshu.png" alt="Shivanshu's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Shivanshu<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Nodejs Developer</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Anirudh.png" alt="Anirudh's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Anirudh<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Nodejs Developer</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/AMAN.png" alt="Aman's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Aman<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Blockchain Developer</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Anmol.png" alt="Anmol's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Anmol<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Blockchain Developer</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>




                        </div>
                        <div className="abt_team_section mt-5">

                          <div className="about_team_mem item4">
                            <div class="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Manish.png" alt="Maneesh's Image" className='teamimg' />
                            </div>
                            <div class="title_abt_team">
                              <p class="team_mem_name">Maneesh<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p class="team_mem_designation">Application Developer</p>
                              <div class="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Kartikey.png" alt="Kartikey's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Kartikey<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Full Stack Developer</p>
                              <div className="team_mem_link">
                                <i className="fab fa-twitter additional-icon"></i>
                                <i className="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Rajneesh.png" alt="Rajneesh's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Rajneesh<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Full Stack Developer</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Manthan.png" alt="Manthan's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Manthan<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Full Stack Developer</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Danish.png" alt="Danish's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Mohd Danish<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">php Developer</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Ashish.png" alt="Ashish's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Ashish<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">php Developer</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>




                        </div>
                        <div className="abt_team_section mt-5">

                          <div className="about_team_mem item4">
                            <div class="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Rakesh.png" alt="Rakesh's Image" className='teamimg' />
                            </div>
                            <div class="title_abt_team">
                              <p class="team_mem_name">Rakesh<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p class="team_mem_designation">Marketing Lead</p>
                              <div class="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Arpit.png" alt="Arpit's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Arpit<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Marketing Manager</p>
                              <div className="team_mem_link">
                                <i className="fab fa-twitter additional-icon"></i>
                                <i className="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Vidit.png" alt="Vidit's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Vidit<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Seo Executive</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/Prashant.png" alt="Prashant's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Prashant<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Seo Executive</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/rehan.png" alt="Rayhan Zaidi's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Rayhan Zaidi<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Legal Advisor</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>
                          <div className="about_team_mem item4">
                            <div className="team_mem_img">
                              <img src="https://www.nadcab.com/public/team/nitin.png" alt="Nitin's Image" className='teamimg' />
                            </div>
                            <div className="title_abt_team">
                              <p className="team_mem_name">Nitin<img src={flag} width="15px" alt="Flag Icon" /></p>
                              <p className="team_mem_designation">Lawyer</p>
                              <div className="team_mem_link">
                                <i class="fab fa-twitter additional-icon"></i>
                                <i class="fab fa-linkedin additional-icon"></i>
                              </div>
                            </div>
                          </div>




                        </div>


                      </div>

                    </section>
                  )}

                  {activeTab === 'item1' && (
                    <section>
                      <div className="abt_team_section mt-5">

                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/Deshraj.png" alt="Deshraj's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Deshraj<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">Senior Developer</p>
                            <div className="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/Anirudh.png" alt="Anirudh's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Anirudh<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">Nodejs Developer</p>
                            <div className="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/AMAN.png" alt="Aman's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Aman<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">Blockchain Developer</p>
                            <div className="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/Manthan.png" alt="Manthan's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Manthan<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">Full Stack Developer</p>
                            <div className="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/Danish.png" alt="Danish's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Mohd Danish<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">php Developer</p>
                            <div className="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/Rajneesh.png" alt="Rajneesh's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Rajneesh<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">Full Stack Developer</p>
                            <div className="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>



                      </div>
                      <div className="abt_team_section mt-5">
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/Kartikey.png" alt="Kartikey's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Kartikey<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">Full Stack Developer</p>
                            <div className="team_mem_link">
                              <i className="fab fa-twitter additional-icon"></i>
                              <i className="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/Anmol.png" alt="Anmol's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Anmol<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">Blockchain Developer</p>
                            <div className="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/Ashish.png" alt="Ashish's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Ashish<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">Web Developer</p>
                            <div className="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/ambuj.png" alt="ambuj's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Ambuj<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">Web Developer</p>
                            <div className="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}

                  {activeTab === 'item2' && (
                    <section>
                      <div className="abt_team_section">
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/viabhav-modified.png" alt="Prince's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Prince<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">Graphic Designer</p>
                            <div className="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/Swati .png" alt="Swati's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Swati<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">UI/UX Designer</p>
                            <div className="team_mem_link">
                              <i className="fab fa-twitter additional-icon"></i>
                              <i className="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                  )}
                  {activeTab === 'item3' && (
                    <section>
                      <h2></h2>
                    </section>
                  )}
                  {activeTab === 'item4' && (
                    <section>
                      <div className="abt_team_section">

                        <div className="about_team_mem item4">
                          <div class="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/amanvaths.png" alt="Aman's Image" className='teamimg' />
                          </div>
                          <div class="title_abt_team">
                            <p class="team_mem_name">Aman<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p class="team_mem_designation">CTO</p>
                            <div class="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/naman.png" alt="Naman's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Naman<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">CEO</p>
                            <div className="team_mem_link">
                              <i className="fab fa-twitter additional-icon"></i>
                              <i className="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                        <div className="about_team_mem item4">
                          <div className="team_mem_img">
                            <img src="https://www.nadcab.com/public/team/wazid.png" alt="Wazid's Image" className='teamimg' />
                          </div>
                          <div className="title_abt_team">
                            <p className="team_mem_name">Wazid<img src={flag} width="15px" alt="Flag Icon" /></p>
                            <p className="team_mem_designation">Director</p>
                            <div className="team_mem_link">
                              <i class="fab fa-twitter additional-icon"></i>
                              <i class="fab fa-linkedin additional-icon"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                  {activeTab === 'item5' && (
                    <section>
                      <h2></h2>
                    </section>
                  )}
                  {activeTab === 'item6' && (
                    <section>
                      <h2></h2>
                    </section>
                  )}
                  {activeTab === 'item7' && (
                    <section>
                      <h2></h2>
                    </section>
                  )}



                </div>



              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="team-sec3">
        <div className="container we-re_tittle">
          <div>
            <h3>Join the team</h3>
            <p>Join the pioneering team researching Trust Id. Join us as we shape technology and influence the future. Start considering professional advancement now.</p>
          </div>
          <div>
            <button className="team_button" role="button" type="button">See open positions</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Meeting
