import axios from "axios";
import React, { useEffect, useState } from "react";
import Topheader from "./Topheader";
import Navbar from "./Navbar";
import Footer from "./Footer";

function Payment() {
  const handleKeyDown = (event) => {
    console.log(event.keyCode, "event:::");
    const keyCode = event.keyCode;
    const allowedKeys =
      event.ctrlKey ||
      event.altKey ||
      (keyCode > 47 && keyCode < 58 && !event.shiftKey) || // number keys
      (keyCode > 95 && keyCode < 106) || // numpad keys
      keyCode === 8 || // backspace
      keyCode === 9 || // tab
      (keyCode > 34 && keyCode < 40) || // arrow keys
      keyCode === 46; // delete

    if (!allowedKeys) {
      event.preventDefault();
    }
  };




  
   

  
  return (
    <div>
       <Topheader />
      <Navbar />
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-lg-6 mx-auto d-block">
            <div className="card pricecard border-0 mt-5">
              <div className="pricecardpayment">
                <form method="POST" action="https://trst.ink/api/pay">
                  <div className="form-group">
                    <label>TrustID Register Phone Number</label>
                    <input
                      type="tel"
                      name="phone"
                      className="form-control"
                      placeholder="Enter phone"
                      required
                      maxLength={10}
                      minLength={1}
                      pattern="\d{10}"
                      onKeyDown={handleKeyDown}
                    
                    />
                    <label class="mt-3">Amount</label>
                    <input
                      type="number"
                      name="amount"
                      className="form-control"
                      placeholder="Enter Amount"
                      required
                      min={1}
                    />

                    <small className="form-text text-muted credittext">
                      20 Credit
                    </small>
                  </div>
                  <button type="submit" className="btn btnbuy mt-3">
                    Pay Amount <i className="fa-solid fa-arrow-right ms-2"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Payment;
