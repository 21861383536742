import React from 'react'
import Topheader from './Topheader'
import Navbar from './Navbar'
import Footer from './Footer'

const Refundcancellation = () => {
  return (
    <>
       <Topheader />
      <Navbar />
      <div className="privacy_and_heading">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <div className="text-center">
                <h1 className=''>Refund & Cancellation</h1>

                {/* <p >Trust ID - Privacy Policy for Refunds and Cancellations</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy_and_policy_area">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <h5 className='privacy_head'>Introduction</h5>
              <p className='privacy_content'>At Trust ID, powered by Naygon Technologies Pvt Ltd, we are committed to the privacy and satisfaction of our customers. This section of our Privacy Policy provides detailed information on our approach to handling refunds and cancellations related to our utility services and digital PVC card prints, including Aadhaar, PAN, and other identification cards.</p>
              <h5 className='privacy_head'>Cancellation Policy</h5>
              <ul>
                <li><strong>Cancellation Requests:</strong> Customers can request a cancellation within 24 hours of placing an order. To cancel your order, please contact our customer service team at [insert contact details].</li>
                <li><strong>Service Cancellation:</strong> In cases where a service or product cannot be delivered due to unforeseen circumstances or limitations from our side, customers will be notified, and a full refund will be processed.</li>
                <li><strong>Digital Products:</strong> Given the nature of digital card services, cancellations may not be possible once the card printing process has commenced. Please review your order carefully before submission.</li>
              </ul>
              <h5 className='privacy_head'>Refund Policy</h5>
              <ul>
                <li><strong>Eligibility:</strong> Refunds are processed based on the eligibility criteria which include non-delivery of service, service discrepancies, and cancellations made within the stipulated time frame.</li>
                <li><strong>Processing Refunds:</strong> Eligible refunds will be processed through the original mode of payment within 7-10 business days, depending on the customer's bank or card service provider.</li>
                <li><strong>Non-refundable Services:</strong> Certain services may be non-refundable. These include services that have been completed or digital products that have been downloaded or used. We encourage customers to read the specific terms and conditions related to each service.</li>
              </ul>

              <h5 className='privacy_head' >Privacy and Data Protection</h5>
              <ul>
                <li><strong>Information Collection:</strong> Trust ID, through Naygon Technologies Pvt Ltd, collects information necessary to fulfill orders and provide services. This information includes, but is not limited to, name, contact details, and identification numbers.</li>
                <li><strong>Data Use:</strong> The information collected is used exclusively for service delivery, order processing, and for improving our services. We do not share or sell your personal information to third parties for marketing purposes.</li>
                <li><strong>Data Security:</strong> We employ industry-standard security measures to protect your information and ensure confidentiality. Our commitment to data protection is integral to our business practices.</li>
              </ul>

              <h5 className='mb-2 fw-bold'>Contact Us</h5>
              <p>For any queries regarding our refunds and cancellations policy, or if you need assistance with an order, please contact our customer support at [insert contact details].</p>

              <h5 className='  mb-2 fw-bold'>Amendments</h5>
              <p>Trust ID reserves the right to modify this privacy policy concerning refunds and cancellations at any time. All changes will be posted on our website and, where applicable, notified to you by email.</p>
            </div>
          </div>
        </div>
      </div>
     <Footer/>
    </>
  )
}

export default Refundcancellation