import React from 'react'
import tree from '../components/image/treecards.png'

function Cardtree() {
  return (
    <section>
      <div className='container'>
            <div className='row justify-content-center'>
          <h1 className='text-center heading_second'>Powerful <span className='Database'>Database</span></h1>
          <p  className='text-center paratext1'>A versatile solution for verifying customer identity,</p>
          <div className='text-center mt-2'>
          <button type='button' className='btn btn-dark me-4 usecasebtn1' style={{ borderRadius: '0' }}>Book a Demo</button>
          </div>
          <div className='col-12 mt-2'>
                <img src={tree} className='mx-auto d-block imgtree '  alt='image'/>
                <p  className='text-center mt-2 paratext'>Backed by Premier investors and operator</p>
            </div>
        </div>
      </div>

        <div className='d-none d-sm-block'>
        <div className='slide_box mt-5'>
          <img src='./assets/image/slider/1.png' className='mx-auto d-block img-fluid slide_img1'/>
          <img src='./assets/image/slider/2.png' className='mx-auto d-block img-fluid slide_img1'/>
          <img src='./assets/image/slider/3.png' className='mx-auto d-block img-fluid slide_img1'/>
          <img src='./assets/image/slider/4.png' className='mx-auto d-block img-fluid slide_img1'/>
          <img src='./assets/image/slider/5.png' className='mx-auto d-block img-fluid slide_img1'/>
          <img src='./assets/image/slider/6.png' className='mx-auto d-block img-fluid slide_img1'/>
          <img src='./assets/image/slider/7.png' className='mx-auto d-block img-fluid slide_img1'/>
        </div>
        </div>

        <div className='d-block d-sm-none'>
        <div className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="./assets/image/slider/1.png" className="slide_img"/>
            </div>
            <div className="carousel-item">
              <img src="./assets/image/slider/2.png" className="slide_img"/>
            </div>
            <div className="carousel-item">
              <img src="./assets/image/slider/3.png" className="slide_img"/>
            </div>
          </div>
        </div>
        </div>


     </section>
  )
}

export default Cardtree
