import React from 'react';
import img from '../components/image/Group 554 (1).png';



function About() {
  const handleClick = () => {
    if (window.Swal) {
      window.Swal.fire({
        title: "",
        html: `
        <iframe class="video_popup" src="https://www.youtube.com/embed/1emO0THdrlU?si=MZNKLMSNs8BncSFJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        `,
        showConfirmButton: false,
        showCloseButton: false,
        backdrop: `rgba(0,0,123,0.4)`
      });
    } 
  };
  return (
    <section className='py-5 my-5'>
      <div className='container'>
        <div className='row g-4 flex-column-reverse flex-md-row'>

          <div className='col-12 col-md-6'>
            <h1 className='home_heading'>Individual and Business <br/><span className='kycverfication'> KYC/AML Verification </span><br/> Suite.</h1>
            <p className='kycpara_heading'>A versatile solution for verifying customer identity, simplifying <br/>  the KYC registration process and managing the entire<br/>  customer lifecycle.</p>
            <a href='#' className='btn search_btn py-2'>Book a Demo</a>
            <a className='btn play_btn' onClick={handleClick}>Use Case <i className="fa-solid fa-circle-play"></i></a>
          </div>

          <div className='col-12 col-md-6'>

            {/* <div className='absolute'>
              <div className='absolute inset-0 justify-center'>
                  <div className='bg-shape1 bg-teal opacity-50 bg-blur'></div>
                  <div className='bg-shape2 bg-red opacity-50 bg-blur'></div>
                  <div className='bg-shape1 bg-purple opacity-50 bg-blur'></div>
              </div>
            </div> */}

          <img src={img} className='mx-auto d-block img-fluid banner_img' />
          </div>

        </div>

        <div className='row g-3 px-md-5 mx-md-5' style={{marginTop:'6rem'}}>

          <div className='col-12 col-md-4'>
            <a href='#' className='btn home_btn article_icn_bg1'>Workflow <i class="fa-solid fa-greater-than icn_bg1"></i></a>
          </div>

          <div className='col-12 col-md-4'>
            <a href='#' className='btn home_btn article_icn_bg2'>ID Verification <i class="fa-solid fa-greater-than icn_bg2"></i></a>
          </div>

          <div className='col-12 col-md-4'>
            <a href='#' className='btn home_btn article_icn_bg3'>AML Screening <i class="fa-solid fa-greater-than icn_bg3"></i></a>
          </div>

        </div>
      </div>
    </section>
  );
}

export default About;
