import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-sm navbar-dark bg-white">
      <div className="container">
        <a href='/' className="navbar-brand">
        <img src="./assets/image/logo.png"/>
      
        </a>
        <a
          className="navbar-toggler"
          href="#"
          data-bs-toggle="collapse"
          data-bs-target="#mynavbar"
          onClick={toggleNavbar}
          style={{ background: 'transparent' }}
        >
          {isOpen ? (
            <span className="navbar-close-icon" style={{ fontSize: '40px' }}>×</span>
          ) : (
            <i className="fas fa-bars"></i>
          )}
        </a>
        <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="mynavbar">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/product" onClick={closeNavbar}>
                Product
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="industries" onClick={closeNavbar}>
                Industries
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/About" onClick={closeNavbar}>
                About
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="company" onClick={closeNavbar}>
                Company
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/Contact" onClick={closeNavbar}>
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Pricing" onClick={closeNavbar}>
                Pricing
              </Link>
            </li>
          </ul>
          {/* <button
            type="button"
            className="me-2 usecasebtn12"
            style={{ borderRadius: "0" }}
          >
            Sign in
          </button> */}
          <a  href="#downloadApp">
          <button
            type="button"
            className="me-2 usecasebtn1"
            style={{ borderRadius: "0" ,whiteSpace:"nowrap"}}
           
          >
           Download APP 
          </button>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
