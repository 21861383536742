import React from 'react'
import Boxcard from './Boxcard';
import Footer from './Footer';
import Topheader from './Topheader';
import Navbar from './Navbar';


function Product() {
 
  return (
    <div>
       <Topheader />
      <Navbar />
      <section className='py-5'>
        <div className='container text-center'>
            <div className='row justify-content-center'>
              <div className='col-12 col-md-8'>
                  <h5 className='sub_title'><span className='title_line'></span>Product<span className='title_line'></span></h5>
                  <h1 className='mt-3 main_heading'>Our Product</h1>
                  <p className='fs_20 px-md-5'>Welcome to ElevateSphere, where we bring together a fusion of creativity and technical mastery to empower your brand's digital journey.</p>

                  <a href='Contact' className='btn default_btn py-2 my-3'>Contact us <i className="fa-solid fa-arrow-right ms-2"></i></a>
              </div>
              
            </div>
           

          </div>  
      </section>   

      <section className='my-md-5 py-md-5'>
      <div className='container ' style={{borderRadius:'15px'}}>
        <div className='row'>

        <div className='col-12 col-md-8 card_bg_mob p-md-0' style={{marginTop:'9px'}}>
            <div className='card card_border radius1'>
              <div className='card-body text-start p-3'>
              <h5><span class="title_line"></span>Point of Access (POA)</h5>
                <h1 className='about_head_product'>POA System: Tailored Access Control</h1>
                <p className='fs_20'>The Trust Id Point of Access (POA) product is designed to enhance security and streamline access control within various environments. This sophisticated system uses advanced identity verification technologies to ensure that only authorized individuals can gain entry to secure areas, buildings, or digital spaces..</p>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-4 card_bg_mob p-md-0 radius2' >
          <div className='card card_border card_bg radius2'>
              <div className='card-body text-start mt-md-4'>
                <img src='./assets/image/poa.svg' className='mx-auto d-block' style={{width:'100%',height:'300px'}}/>
                </div>
            </div>
            </div>

            <div className='col-12 col-md-12 card_bg_mob  radius3'>
      <div className='card card_border card_bg radius3'>
        <div className='card-body text-start pe-md-auto'>
          
          <div className='bg-white p-3' style={{ borderRadius: '15px' }}>
            
              <p className='fs_16'>
              <span className='fw-600'>Advanced Verification </span>: Integrates seamlessly with biometric, QR code, or RFID technologies to provide reliable and efficient identity verification, ensuring that access is granted only to verified individuals.
              <br/>
              <span className='fw-600'>Customizable Access Levels </span>: Offers the flexibility to set different access levels for individuals based on their roles or clearance, enhancing security protocols and ensuring that individuals can only enter areas they are authorized to access.
              <br/>
              <span className='fw-600'>Real-time Monitoring </span>:Equipped with capabilities for real-time monitoring and logging of access events, allowing for detailed audit trails and enhanced security oversigh
              <br/>
              <span className='fw-600'>Easy Integration</span>: Designed to work seamlessly with existing security infrastructure, minimizing the need for extensive modifications and ensuring a smooth implementation process.
              <br/>
              <span className='fw-600'>User-friendly Interface</span>: Features an intuitive interface for both administrators and users, simplifying the process of managing access permissions and using the system.
              </p>

          </div>
        </div>
      </div>
    </div>

        </div>
      </div>
    </section>

    <section className='my-5 py-5'>
      <div className='container ' style={{borderRadius:'15px'}}>
        <div className='row'>

        <div className='col-12 col-md-4 card_bg_mob p-md-0 rev_radius3' >
          <div className='card card_border card_bg rev_radius3'>
              <div className='card-body text-start mt-md-5'>
                <img src='./assets/image/sound box2.svg' className='mx-auto d-block' style={{width:'100%',height:'300px'}}/>
                </div>
            </div>
            </div>

        <div className='col-12 col-md-8 card_bg_mob p-md-0' style={{marginTop:'9px'}}>
            <div className='card card_border rev_radius1'>
              <div className='card-body text-start p-5'>
              <h5><span class="title_line"></span>Soundbox</h5>
                <h1 className='about_head_product'>TrustBox: Audible KYC Verification for Enhanced Security</h1>
                <p className='fs_16'>TrustBox by Trust Id revolutionizes the landscape of identity verification with its innovative, audible confirmation technology. Tailored for environments requiring secure and swift identity checks, TrustBox offers an intuitive solution that bridges the gap between high security and operational efficiency.</p>
              </div>
            </div>
          </div>
          

            

          <div className='col-12 col-md-4 card_bg_mob rev_radius1'>
          <div className='card card_border card_bg rev_radius1'>
              
            </div>
            </div> 

            <div className='col-12 col-md-12 card_bg_mob  rev_radius2'>
      <div className='card card_border card_bg rev_radius2'>
        <div className='card-body text-start ps-md-0'>
          
          <div className='bg-white p-4' style={{ borderRadius: '15px' }}>
            
              <p className='fs_16'>
              <span className='fw-600'>Instant Audio Feedback </span>: TrustBox confirms identity verification with clear, audible signals, ensuring both users and operators receive immediate confirmation.
                <br/>
                <span className='fw-600'>Customizable Alerts</span>: Tailor sound alerts to fit different verification outcomes, providing flexibility across various scenarios.
                <br/>
                <span className='fw-600'>User-Friendly</span> : Designed for ease of use, TrustBox simplifies the verification process, enhancing customer and employee experiences.
                <br/>
                <span className='fw-600'>Enhanced Security</span> : Integrates advanced encryption to safeguard data during verification, significantly reducing fraud risk.
                <br/>
                <span className='fw-600'> Versatile Application </span> : Ideal for retail, banking, and events, TrustBox is adaptable to any environment requiring secure, fast identity verification.
                <br/>
                <span className='fw-600'>Seamless Integration</span> : Easily connects with existing Trust Id systems and workflows, offering a plug-and-play solution for businesses.
              </p>

          </div>
        </div>
      </div>
    </div>
           

        </div>
      </div>
    </section>

    <section className='my-5 py-5'>
      <div className='container ' style={{borderRadius:'15px'}}>
        <div className='row'>

        <div className='col-12 col-md-8 card_bg_mob p-md-0' style={{marginTop:'9px'}}>
            <div className='card card_border radius1'>
              <div className='card-body text-start p-3'>
              <h5><span class="title_line"></span>Trust QR Standy</h5>
                <h1 className='about_head_product'>Trust QR Standy: Contactless Verification Made Simple</h1>
                <p className='fs_16'>Trust QR Standy by Trust Id is a cutting-edge solution designed to streamline and secure the identity verification process through the use of dynamic QR codes. This standalone device is ideal for businesses and organizations looking to enhance security and improve operational efficiency without compromising on user experience.</p>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-4 card_bg_mob p-md-0 radius2' >
          <div className='card card_border card_bg radius2'>
              <div className='card-body text-start'>
                <img src='./assets/image/trustqr1.svg' className='mx-auto d-block' style={{width:'100%',height:'300px'}}/>
                </div>
            </div>
            </div>

            <div className='col-12 col-md-12 card_bg_mob p-md-0 radius3'>
      <div className='card card_border card_bg radius3'>
        <div className='card-body text-start '>
          
          <div className='bg-white p-4' style={{ borderRadius: '15px' }}>
            
              <p className='fs_16'>
              <span className='fw-600'>Contactless Identity Verification </span>:
               Enables fast and secure verification through QR code scanning, minimizing physical contact.
               <br/>
              <span className='fw-600'>  User-Friendly Interface</span>:
              Designed for ease of use, ensuring a smooth experience for both businesses and users.
              <br/>
              <span className='fw-600'>Versatile Applications </span>:
              Ideal for retail, events, and corporate environments, adaptable to various verification needs.
              <br/>
              <span className='fw-600'> Seamless Integration</span>:
              Easily integrates with existing Trust Id systems for a cohesive security solution.
              <br/>
              <span className='fw-600'> Enhanced Data Protection</span>:
              Employs advanced encryption to safeguard personal information during the verification process.
              <br/>
              <span className='fw-600'> Customizable Display</span>:
              Offers options to customize the display for branding or specific instructions, enhancing user engagement.
              
              </p>

          </div>
        </div>
      </div>
    </div>

         

        
           

        </div>
      </div>
    </section>

    <section className='my-5 py-5'>
      <div className='container ' style={{borderRadius:'15px'}}>
        <div className='row'>

        <div className='col-12 col-md-4 card_bg_mob p-md-0 rev_radius3' >
          <div className='card card_border card_bg rev_radius3'>
              <div className='card-body text-start'>
                <img src='./assets/image/DOOR ASSEC KIT.svg' className='mx-auto d-block' style={{width:'100%',height:'300px'}}/>
                </div>
            </div>
            </div>

        <div className='col-12 col-md-8 card_bg_mob p-md-0' style={{marginTop:'9px'}}>
            <div className='card card_border rev_radius1'>
              <div className='card-body text-start p-3'>
              <h5><span class="title_line"></span>Door Access Kit (DAC)</h5>
                <h1 className='about_head_product'>Door Access Kit (DAC): Secure, Keyless Entry</h1>
                <p className='fs_20'>The Door Access Kit (DAC) by Trust Id revolutionizes access control with secure, keyless entry technology. Designed for versatility and ease of use, DAC offers a reliable solution for managing entry to buildings and facilities, ensuring only authorized access through advanced verification methods</p>
              </div>
            </div>
          </div>
          


            <div className='col-12 col-md-12 card_bg_mob  rev_radius2'>
      <div className='card card_border card_bg rev_radius2'>
        <div className='card-body text-start ps-md-0'>
          
          <div className='bg-white p-3' style={{ borderRadius: '15px' }}>
            
              <p className='fs_16'>
              <span className='fw-600'> Keyless Convenience</span>:
              Eliminate physical keys with digital access control.
              <br/>
              <span className='fw-600'>  Advanced Verification</span>:
              Utilizes biometrics, QR codes, or PINs for secure entry.
              <br/>
              <span className='fw-600'>Easy Integration</span>:
              Seamlessly fits into existing security systems
              <br/>
              <span className='fw-600'>Customizable Access Levels</span>:
              Tailor entry permissions for individuals or groups.
              <br/>
              <span className='fw-600'>Real-Time Monitoring</span>:
              Track entry and exit for enhanced security.
              <br/>
              <span className='fw-600'>User-Friendly Management</span>:
              Simple interface for easy administration of access rights
              <br/>

            
              </p>

          </div>
        </div>
      </div>
    </div>
           

        </div>
      </div>
    </section>

     
    <Footer />
    </div>
  )
}

export default Product
