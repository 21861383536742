import React from 'react'
import Topheader from './Topheader'
import Navbar from './Navbar'
import Footer from './Footer'

const Shippingdelivery = () => {
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="privacy_and_heading">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <div className="text-center">
                <h1 className=''>Shipping & Delivery</h1>
                {/* <p>Trust ID, a service provided by Naygon Technologies Pvt Ltd, is committed to ensuring the timely and secure delivery of our utility services and digital PVC card prints, including Aadhaar, PAN, and other identification documents. This Shipping and Delivery Policy outlines our practices and commitments to you regarding the shipment of physical products.</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy_and_policy_area">
        <div className="container">
          <div className="content">
            <div className="col-md-12">
              <h5 className='privacy_head'>Shipping Methods</h5>

              <ul>
                <li><strong>Carriers:</strong> Trust ID partners with reputable carriers to ensure the safe and efficient delivery of your orders. Our primary shipping partners include [insert carrier names], among others.</li>
                <li><strong>Shipping Charges:</strong> Shipping charges, if applicable, are calculated based on the delivery address, weight of the package, and the shipping method selected. These charges will be clearly communicated at the time of order placement.</li>
              </ul>

              <h5 className='privacy_head'>Delivery Times</h5>
              <ul>
                <li><strong>Processing Time:</strong> Orders are typically processed within 3-4 days of order placement. This includes verification, printing, and packaging of your digital PVC cards.</li>
                <li><strong>Estimated Delivery Time:</strong> While we strive to deliver your orders within 4-5 days, actual delivery times may vary due to factors beyond our control such as carrier delays or weather conditions. Estimated delivery times will be provided at the time of order confirmation.</li>
              </ul>

              <h5 className='privacy_head'>Tracking Your Order</h5>
              <ul>
                <li><strong>Order Tracking:</strong> Upon dispatch, you will receive a tracking number that allows you to monitor the status of your shipment via the carrier’s website.</li>
                <li><strong>Customer Support:</strong> For any queries related to your order’s shipping and delivery status, please contact our customer support team at [insert contact details].</li>
              </ul>

              <h5 className='privacy_head'>Delivery Issues</h5>
              <ul>
                <li><strong>Damaged or Lost Packages:</strong> In the rare event that your package is damaged upon arrival or lost in transit, please contact us immediately. Trust ID will work with the carrier to resolve the issue and, if necessary, re-ship the product at no additional cost to you.</li>
                <li><strong>Incorrect Delivery Address:</strong> It is crucial to provide an accurate delivery address at the time of order. Trust ID is not responsible for packages delivered to an incorrect address provided by the customer. If a package is returned to us due to an incorrect address, we will contact you for the correct address and re-ship the product at your expense.</li>
              </ul>

              <h5 className='privacy_head'>Privacy and Data Protection</h5>
              <p>Trust ID, through Naygon Technologies Pvt Ltd, respects your privacy and is committed to protecting your personal information throughout the shipping and delivery process. Information collected for shipping purposes is used solely for the delivery of your order and is shared with our shipping partners in compliance with our Privacy Policy.</p>

              <h5 className='privacy_head mb-1'>Return Policy</h5>
              <p>No Return Policy</p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Shippingdelivery