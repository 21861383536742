import React, { useState, useEffect } from "react";
import Boxcard from "./Boxcard";
import Topheader from "./Topheader";
import Navbar from "./Navbar";
import Footer from "./Footer";
// import './resousre.css';

function Resources() {
  const [revenue, setRevenue] = useState(0);
  const [teamMembers, setTeamMembers] = useState(0);
  const [projectsCompleted, setProjectsCompleted] = useState(0);
  const [clientsWorldwide, setClientsWorldwide] = useState(0);

  useEffect(() => {
    const revenueInterval = setInterval(() => {
      setRevenue((revenue) => revenue + 1);
    }, 20);

    const teamMembersInterval = setInterval(() => {
      setTeamMembers((teamMembers) => teamMembers + 1);
    }, 20);

    const projectsCompletedInterval = setInterval(() => {
      setProjectsCompleted((projectsCompleted) => projectsCompleted + 1);
    }, 20);

    const clientsWorldwideInterval = setInterval(() => {
      setClientsWorldwide((clientsWorldwide) => clientsWorldwide + 1);
    }, 20);

    return () => {
      clearInterval(revenueInterval);
      clearInterval(teamMembersInterval);
      clearInterval(projectsCompletedInterval);
      clearInterval(clientsWorldwideInterval);
    };
  }, []);

  return (
    <div>
         <Topheader />
      <Navbar />
      <section className="py-5">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h5 className="sub_title">
                <span className="title_line"></span>Resources
                <span className="title_line"></span>
              </h5>
              <h1 className="mt-3 main_heading">
                Transforming Digital Visions into Reality
              </h1>
              <p className="fs_20 px-md-5">
                At Trust Id, we are not just another digital marketing agency;
                we are your dedicated partner in transforming your digital
                vision into reality.
              </p>

              <a href="Contact" className="btn default_btn py-2 my-3">
                Contact us <i className="fa-solid fa-arrow-right ms-2"></i>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 mb-5">
        <div className="container text-center px-md-4">
          <div className="row g-4 justify-content-center">
            <div className="col-12 col-md-3">
              <div className="card bg1 article_card">
                <div className="card-body p-5">
                  <h2 className="card_head">
                    {revenue > 85 ? "85M+" : revenue}
                  </h2>
                  <p className="text-muted fs_20 bold mb-0">
                    Revenue Generated
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="card bg2 article_card">
                <div className="card-body p-5">
                  <h2 className="card_head">
                    {teamMembers > 50 ? "50+" : teamMembers}
                  </h2>
                  <p className="text-muted fs_20 bold mb-0">Team Members</p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="card bg3 article_card">
                <div className="card-body p-5">
                  <h2 className="card_head">
                    {projectsCompleted > 500 ? "500+" : projectsCompleted}
                  </h2>
                  <p className="text-muted fs_20 bold mb-0">
                    Project Completed
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="card bg4 article_card">
                <div className="card-body p-5">
                  <h2 className="card_head">
                    {clientsWorldwide > 250 ? "250+" : clientsWorldwide}
                  </h2>
                  <p className="text-muted fs_20 bold mb-0">
                    Clients Worldwide
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-about-info mb-5">
        <div className="container-medium">
          <div className="careers-culture-flex">
            <div className="careers-culture-flex-text text_left">
              <div className="text-label text-color-green text-style-caps">
                <h5 className="sub_title1">
                  <span className="title_line"></span>Our company
                </h5>
              </div>
              <div className="margin-box top-12-bottom-40">
                <div className="max-width _460 tablet-full-width">
                  <h2 className="second_heading">Who we are</h2>
                </div>
              </div>
              <p className="text-size-medium">
                Trust Id is product of{" "}
                <strong>
                  NAYGON TECHNOLOGIES PRIVATE LIMITED
                </strong>{" "}
                global financial technology company building solutions that
                connect the Trust Id economy with today’s financial
                infrastructure.
                <br />
                <br />
                Since 2018, we’ve been building and improving our core Trust Id
                products to take Trust Id mainstream in a secure and compliant
                way.
                <br />
                <br />
                With offices in the UK, US, and Poland, Trust Id employs more
                than 160 people from all over the globe.
                <br />
                <br />
                Leveraging the years of collective fintech experience of our
                team, we’re working together with a network of market-leading
                strategic partners, backers, and advocacy organizations to bring
                about meaningful change.
              </p>
            </div>
            <div class="careers-culture-flex-images">
              <img
                src="./assets/image/team_img3.png"
                loading="lazy"
                width="406"
                alt="trust id About"
                srcset="./assets/image/team_img3.png 500w, ./assets/image/team_img3.png 800w, ./about/team_img3.png 812w"
                sizes="(max-width: 767px) 100vw, 405.9895935058594px"
                class="careers-culutre-image big"
              />
              <img
                src="./assets/image/348548387_1603862466765229_849681563548442943_n.png"
                loading="lazy"
                width="184"
                alt="Office space photo"
                class="careers-culutre-image"
              />
              <img
                src="./assets/image/IMG_1588.svg"
                loading="lazy"
                width="82"
                alt="Team member photo"
                class="careers-culutre-image _2"
              />
            </div>
          </div>
          <div className="mob-img-abt-new">
            <div style={{ width: "291px" }}>
              <img
                src="./assets/image/team_img3.png"
                loading="lazy"
                width=""
                alt="Ramp About"
                style={{ borderRadius: "16px" }}
              />
            </div>
            <div style={{ width: "291px" }}>
              <img
                src="./assets/image/348548387_1603862466765229_849681563548442943_n.png"
                loading="lazy"
                style={{ borderRadius: "16px" }}
              />
            </div>
            <div style={{ width: "291px" }}>
              <img
                src="./assets/image/IMG_1588.svg"
                loading="lazy"
                sizes="(max-width: 991px) 280px, 100vw"
                style={{ borderRadius: "16px" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section-about-info mb-5">
        <div className="container-medium">
          <div className="careers-culture-flex">
            <div className="careers-culture-flex-images">
              <img
                src="./assets/image/GP.svg"
                loading="lazy"
                width="406"
                alt="trust id2 About"
                srcset="./assets/image/GP.svg 500w, ./assets/image/GP.svg 812w"
                sizes="(max-width: 767px) 100vw, 405.9895935058594px"
                className="careers-culutre-image big _2"
              />
              <img
                src="./assets/image/MAN-VASTHS-IMAGE-696x464.svg"
                loading="lazy"
                width="184"
                alt="Team members image"
                className="careers-culutre-image _3"
              />
              <img
                src="./assets/image/WkM5cVh3Wnk1aU5sOE1xZElOZjVUdz09.png"
                loading="lazy"
                width="82"
                alt="Team member photo"
                className="careers-culutre-image _4"
              />
            </div>
            <div className="careers-culture-flex-text text_left">
              <div className="text-label text-color-green text-style-caps">
                <h5 className="sub_title1">
                  <span className="title_line"></span>OUR VISION
                </h5>
              </div>
              <div className="margin-box top-12-bottom-40">
                <div className="max-width _460 tablet-full-width">
                  <h2 className="second_heading">Trust Id the new economy</h2>
                </div>
              </div>
              <p className="text-size-medium">
                Trust Id are not just transforming our financial system, but
                also the ways in which we create and share value.
                <br />
                <br />
                We`re harnessing the power of this technology to shape a new,
                better economy - a more efficient and equitable one, and one
                that works for the many, not just the few.
              </p>
              <div className="margin-box top-70">
                <div className="text-label memberof_point">MEMBER OF</div>
                <div className="about-info-logo-flex">
                  <a
                    data-gtm-type="regular"
                    data-gtm-area="content"
                    href="#"
                    target="_blank"
                    rel="noreferrer"
                    className="w-inline-block"
                  >
                    <img
                      src="./assets/image/644b3e719881d7d9d9d2464c_fx4-about-logo-1.svg"
                      loading="lazy"
                      alt="EMA logo"
                      width="63"
                    />
                  </a>
                  <a
                    data-gtm-type="regular"
                    data-gtm-area="content"
                    href="#"
                    target="_blank"
                    rel="noreferrer"
                    className="w-inline-block"
                  >
                    <img
                      src="./assets/image/644b3e719881d7d9d9d2464c_fx4-about-logo-1.svg"
                      loading="lazy"
                      alt="Inatba logo"
                      width="32"
                    />
                  </a>
                  <a
                    data-gtm-type="regular"
                    data-gtm-area="content"
                    href="#"
                    target="_blank"
                    rel="noreferrer"
                    className="w-inline-block"
                  >
                    <img
                      src="./assets/image/644b3e719881d7d9d9d2464c_fx4-about-logo-1.svg"
                      loading="lazy"
                      alt="Fintech Fincrime Exchange logo"
                      width="50"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mob-img-abt-new mt-4">
            <div style={{ width: "291px" }}>
              <img
                src="./assets/image/GP.svg"
                loading="lazy"
                width=""
                alt="Ramp About"
                style={{ borderRadius: "16px" }}
              />
            </div>
            <div style={{ width: "291px" }}>
              <img
                src="./assets/image/MAN-VASTHS-IMAGE-696x464.svg"
                loading="lazy"
                style={{ borderRadius: "16px" }}
              />
            </div>
            <div style={{ width: "291px" }}>
              <img
                src="./assets/image/WkM5cVh3Wnk1aU5sOE1xZElOZjVUdz09.png"
                loading="lazy"
                sizes="(max-width: 991px) 280px, 100vw"
                style={{ borderRadius: "16px" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="showcase">
        <img
          src="./assets/image/blur3.png"
          alt="Picture"
          className="blurnone"
        />
        <div className="overlay">
          <div className="show-c-tittle">
            <p className="ourpatnerheadin">our values </p>
            <h2>What we believe in</h2>
          </div>
          <div className="container">
            <div className="img-show-row row">
              <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6">
                <div className="center-img">
                  <div className="values-icon-wrapper">
                    <img src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c435c4c4cc14a33c8fc_ramp-values-icon-2.svg" />
                  </div>
                  <h3 className="heading_inclusive">Inclusive</h3>
                  <p>To make Trust Id accessible to everyone, everywhere</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6">
                <div className="center-img">
                  <div className="values-icon-wrapper">
                    <img
                      src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c435c4c4cc14a33c8fc_ramp-values-icon-2.svg"
                      alt="Feature icon"
                    />
                  </div>
                  <h3 className="heading_inclusive">Efficient</h3>
                  <p>To offer an automated and frictionless experience</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6">
                <div className="center-img">
                  <div className="values-icon-wrapper">
                    <img
                      src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c42e1038c546bdf8bed_ramp-values-icon-3.svg"
                      alt="Feature icon"
                    />
                  </div>
                  <h3 className="heading_inclusive">Reliable</h3>
                  <p>To be as transparent and verifiable as we can</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-6 col-xs-6">
                <div className="center-img">
                  <div className="values-icon-wrapper">
                    <img
                      src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c43a1ed08415d653f4c_ramp-values-icon-4.svg"
                      alt="Feature icon"
                    />
                  </div>
                  <h3 className="heading_inclusive">Complaint</h3>
                  <p>To engage regulators and comply with existing rules</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="abt_section_5_main">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-sm-12">
                <div class="abt-sec-5">
                  <h5 className="sub_title1">
                    <span className="title_line"></span>our people's
                  </h5>
                  <h3>Key Founders</h3>
                  <p>
                    Their visionary leadership has propelled Trust Id to the
                    forefront of the Trust Id revolution, where trust is no
                    longer centralized within Trust Id but distributed across
                    the netwo
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="about-people-flex-right">
                  <div className="about-people-card">
                    <div
                      style={{
                        backgroundImage:
                          "url('https://www.nadcab.com/public/team/av1.png')",
                      }}
                      className="people-card-image"
                    ></div>
                    <h3 className="text-people-heading">Aman Vaths</h3>
                    <div className="text-people-caption">CTO & Founder</div>
                    <div className="people-social-icons">
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2bbde7a7820a779be9_ramp-twitter-icon.svg"
                          loading="lazy"
                          alt="Twitter icon"
                        />
                      </a>
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2ba53a59f2faa6b5ae_ramp-linkedin-icon.svg"
                          loading="lazy"
                          alt="LinkedIn icon"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="about-people-card">
                    <div
                      style={{
                        backgroundImage:
                          "url('https://www.nadcab.com/public/team/nmn.png')",
                      }}
                      className="people-card-image"
                    ></div>
                    <h3 className="text-people-heading">Naman Singh</h3>
                    <div className="text-people-caption">CEO & Co-Founder</div>
                    <div className="people-social-icons">
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2bbde7a7820a779be9_ramp-twitter-icon.svg"
                          loading="lazy"
                          alt="Twitter icon"
                        />
                      </a>
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2ba53a59f2faa6b5ae_ramp-linkedin-icon.svg"
                          loading="lazy"
                          alt="LinkedIn icon"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="about-people-card">
                    <div
                      style={{
                        backgroundImage:
                          "url('https://www.nadcab.com/public/team/wk.png')",
                      }}
                      className="people-card-image"
                    ></div>
                    <h3 className="text-people-heading">Wazid Khan</h3>
                    <div className="text-people-caption">
                      Director & Co-Founder
                    </div>
                    <div className="people-social-icons">
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2bbde7a7820a779be9_ramp-twitter-icon.svg"
                          loading="lazy"
                          alt="Twitter icon"
                        />
                      </a>
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2ba53a59f2faa6b5ae_ramp-linkedin-icon.svg"
                          loading="lazy"
                          alt="LinkedIn icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="abt_section_5_main">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="abt-sec-5">
                  <h5 className="sub_title1">
                    <span className="title_line"></span>our company
                  </h5>
                  <h3>Senior management</h3>
                  <p>
                    Our senior leadership is comprised of seasoned executives
                    with years of industry experience, both in the mainstream
                    and at the bleeding edge of innovation.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="about-people-flex-right">
                  <div className="about-people-card">
                    <div
                      style={{
                        backgroundImage:
                          "url('https://www.nadcab.com/public/team/Deshraj.png')",
                      }}
                      className="people-card-image"
                    ></div>
                    <h3 className="text-people-heading">Deshraj Singh</h3>
                    <div className="text-people-caption"></div>
                    <div className="people-social-icons">
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2bbde7a7820a779be9_ramp-twitter-icon.svg"
                          loading="lazy"
                          alt="Twitter icon"
                        />
                      </a>
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2ba53a59f2faa6b5ae_ramp-linkedin-icon.svg"
                          loading="lazy"
                          alt="LinkedIn icon"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="about-people-card">
                    <div
                      style={{
                        backgroundImage:
                          "url('https://www.nadcab.com/public/team/Simran.png')",
                      }}
                      className="people-card-image"
                    ></div>
                    <h3 className="text-people-heading">Simran Kaur</h3>
                    <div className="text-people-caption">Hr Manager</div>
                    <div className="people-social-icons">
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2bbde7a7820a779be9_ramp-twitter-icon.svg"
                          loading="lazy"
                          alt="Twitter icon"
                        />
                      </a>
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2ba53a59f2faa6b5ae_ramp-linkedin-icon.svg"
                          loading="lazy"
                          alt="LinkedIn icon"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="about-people-card">
                    <div
                      style={{
                        backgroundImage:
                          "url('https://www.nadcab.com/public/team/Anirudh.png')",
                      }}
                      className="people-card-image"
                    ></div>
                    <h3 className="text-people-heading">Aniruddh Singh</h3>
                    <div className="text-people-caption"></div>
                    <div className="people-social-icons">
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2bbde7a7820a779be9_ramp-twitter-icon.svg"
                          loading="lazy"
                          alt="Twitter icon"
                        />
                      </a>
                      <a
                        data-gtm-area="content"
                        href="#"
                        target="_blank"
                        className="w-inline-block"
                      >
                        <img
                          src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/64274c2ba53a59f2faa6b5ae_ramp-linkedin-icon.svg"
                          loading="lazy"
                          alt="LinkedIn icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5 mb-5 media-fea">
        <div className="container text-center px-md-4">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h5 className="sub_titlefeatureon">
                <span className="title_line"></span>FEATURED ON
                <span className="title_line"></span>
              </h5>
              <h1 className="main_heading123 mb-5">Trust Id in the media</h1>
            </div>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-12 col-md-3">
              <div className="card bgon5 article_card radius_20">
                <div className="card-body text-start p-4 mb-5">
                  <img
                    src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/645d3b1c85bd184674877ebf_FinancialTimes.svg"
                    className="mb-3 mt-4"
                  />
                  <p className="text-mutedon fs_16 mb-2">
                    Trust Id payments start-up Ramp this week announced a 52.7m
                    funding round.
                  </p>
                  <a href="#" className="fs_20 learnon">
                    Learn More <i className="fa-solid fa-arrow-right ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="card bgon5 article_card radius_20">
                <div className="card-body text-start p-4 mb-5">
                  <img
                    src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/645d3b1c85bd184674877ebf_FinancialTimes.svg"
                    className="mb-3 mt-4"
                  />
                  <p className="text-mutedon fs_16 mb-2">
                    Trust Id payments start-up Ramp this week announced a 52.7m
                    funding round.
                  </p>
                  <a href="#" className="fs_20 learnon">
                    Learn More <i className="fa-solid fa-arrow-right ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="card bgon5 article_card radius_20">
                <div className="card-body text-start p-4 mb-5">
                  <img
                    src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/645d3b1c85bd184674877ebf_FinancialTimes.svg"
                    className="mb-3 mt-4"
                  />
                  <p className="text-mutedon fs_16 mb-2">
                    Trust Id payments start-up Ramp this week announced a 52.7m
                    funding round.
                  </p>
                  <a href="#" className="fs_20 learnon">
                    Learn More <i className="fa-solid fa-arrow-right ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="card bgon5 article_card radius_20">
                <div className="card-body text-start p-4 mb-5">
                  <img
                    src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/645d3b1c85bd184674877ebf_FinancialTimes.svg"
                    className="mb-3 mt-4"
                  />
                  <p className="text-mutedon fs_16 mb-2">
                    Trust Id payments start-up Ramp this week announced a 52.7m
                    funding round.
                  </p>
                  <a href="#" className="fs_20 learnon">
                    Learn More <i className="fa-solid fa-arrow-right ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="card bgon5 article_card radius_20">
                <div className="card-body text-start p-4 mb-5">
                  <img
                    src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/645d3b1c85bd184674877ebf_FinancialTimes.svg"
                    className="mb-3 mt-4"
                  />
                  <p className="text-mutedon fs_16 mb-2">
                    Trust Id payments start-up Ramp this week announced a 52.7m
                    funding round.
                  </p>
                  <a href="#" className="fs_20 learnon">
                    Learn More <i className="fa-solid fa-arrow-right ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="card bgon5 article_card radius_20">
                <div className="card-body text-start p-4 mb-5">
                  <img
                    src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/645d3b1c85bd184674877ebf_FinancialTimes.svg"
                    className="mb-3 mt-4"
                  />
                  <p className="text-mutedon fs_16 mb-2">
                    Trust Id payments start-up Ramp this week announced a 52.7m
                    funding round.
                  </p>
                  <a href="#" className="fs_20 learnon">
                    Learn More <i className="fa-solid fa-arrow-right ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="card bgon5 article_card radius_20">
                <div className="card-body text-start p-4 mb-5">
                  <img
                    src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/645d3b1c85bd184674877ebf_FinancialTimes.svg"
                    className="mb-3 mt-4"
                  />
                  <p className="text-mutedon fs_16 mb-2">
                    Trust Id payments start-up Ramp this week announced a 52.7m
                    funding round.
                  </p>
                  <a href="#" className="fs_20 learnon">
                    Learn More <i className="fa-solid fa-arrow-right ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="card bgon5 article_card radius_20">
                <div className="card-body text-start p-4 mb-5">
                  <img
                    src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/645d3b1c85bd184674877ebf_FinancialTimes.svg"
                    className="mb-3 mt-4"
                  />
                  <p className="text-mutedon fs_16 mb-2">
                    Trust Id payments start-up Ramp this week announced a 52.7m
                    funding round.
                  </p>
                  <a href="#" className="fs_20 learnon">
                    Learn More <i className="fa-solid fa-arrow-right ms-1"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h5 className="sub_title">
                <span className="title_line"></span>CAREERS
                <span className="title_line"></span>
              </h5>
              <h1 className="mt-3 Find_heading">Find up your career</h1>
              <p className="fs_16 px-md-5">
                We are looking for exceptionally talented people to join our
                team and help us on our mission to take Trust Id to everyone.
              </p>

              <a href="Contact" className="btn default_btn py-2 my-3">
                Check Jobs <i className="fa-solid fa-arrow-right ms-2"></i>
              </a>
            </div>
          </div>
          <div className="row">
            <div class="about-career-flex">
              <div class="about-career-image">
                <img
                  src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec1f962ac08ebcd576f_Photo1.webp"
                  loading="lazy"
                  width="285"
                  sizes="(max-width: 767px) 100vw, 285px"
                  alt="Office space &amp; team members photo"
                  srcset="
        https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec1f962ac08ebcd576f_Photo1-p-500.webp 500w,
        https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec1f962ac08ebcd576f_Photo1.webp       570w
      "
                  class="career-image-m"
                />
              </div>
              <div class="about-career-image margin-top-40">
                <img
                  src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec1c8a4055047a48abf_Photo2.webp"
                  loading="lazy"
                  width="285"
                  alt="Team member image"
                  class="career-image-m"
                />
              </div>
              <div class="about-career-image">
                <img
                  src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec10e5c462c303524df_Photo3.webp"
                  loading="lazy"
                  width="285"
                  sizes="(max-width: 767px) 100vw, 285px"
                  alt="Office space image"
                  srcset="
        https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec10e5c462c303524df_Photo3-p-500.webp 500w,
        https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec10e5c462c303524df_Photo3.webp       570w
      "
                  class="career-image-m"
                />
              </div>
              <div class="about-career-image margin-top-40">
                <img
                  src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec1606bfb0e275d21a9_Photo4.webp"
                  loading="lazy"
                  width="285"
                  alt="Team members image"
                  class="career-image-m"
                />
              </div>
              <div class="about-career-image">
                <img
                  src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec18bedfcc70e19fe41_Photo5.webp"
                  loading="lazy"
                  width="285"
                  sizes="(max-width: 767px) 100vw, 285px"
                  alt="Office space photo"
                  srcset="
        https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec18bedfcc70e19fe41_Photo5-p-500.webp 500w,
        https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec18bedfcc70e19fe41_Photo5.webp       570w
      "
                  class="career-image-m"
                />
              </div>
              <div class="about-career-image margin-top-40">
                <img
                  src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec1494bfd9f4f1eac30_Photo6.webp"
                  loading="lazy"
                  width="285"
                  alt="Team members image"
                  class="career-image-m"
                />
              </div>
            </div>

            <div className="d-block d-sm-none">
              <div className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec1f962ac08ebcd576f_Photo1.webp"
                      className="slide_img"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec1c8a4055047a48abf_Photo2.webp"
                      className="slide_img"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec10e5c462c303524df_Photo3.webp"
                      className="slide_img"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec1606bfb0e275d21a9_Photo4.webp"
                      className="slide_img"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec18bedfcc70e19fe41_Photo5.webp"
                      className="slide_img"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="https://assets-global.website-files.com/63fe1b7ead2cd2d5e0af02e7/646cdec1494bfd9f4f1eac30_Photo6.webp"
                      className="slide_img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="our_mission py-5">
        <div className="container text-center p-md-5">
          <div className="row g-4 justify-content-center align-items-center">
            <div className="col-12 col-md-6 text-start">
              <h5 className="sub_title">
                <span className="title_line"></span>Our Mission
              </h5>
              <h1 className="article_head my-4">
                Empowering Your Digital Success
              </h1>
              <p className="fs_20">
                At ElevateSphere, our mission is clear: to empower businesses to
                thrive in the digital landscape. We are dedicated to providing
                tailored, innovative solutions that propel our clients towards
                their goals.
              </p>

              <a href="#" className="btn default_btn py-2">
                Learn More <i className="fa-solid fa-arrow-right ms-2"></i>
              </a>
            </div>

            <div className="col-12 col-md-6">
              <img
                src="./assets/image/resources1.png"
                className="img-fluid mx-auto d-block"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-5 mb-5">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <h5 className="sub_title">
                <span className="title_line"></span>Our Vision
                <span className="title_line"></span>
              </h5>
              <h1 className="mt-3 article_head ">Why Choose ElevateSphere?</h1>
              <p className="fs_20 px-md-5 mx-md-5">
                Empowering businesses to effortlessly transform ideas into
                successful digital ventures.
              </p>
            </div>
          </div>

          <div className="row g-4 justify-content-center">
            <div className="col-12 col-md-6">
              <div className="card bg5 article_card radius_20">
                <div className="card-body text-start p-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="icon_box">
                      <img
                        src="./assets/image/resources_icn1.png"
                        className="icon_box_img mx-auto d-block"
                      />
                    </div>
                    <div className="ms-4">
                      <h3 className="my-2 article_head1">Experience</h3>
                      <p className="text-muted">
                        With years of experience, we have a proven track record
                        of success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card bg5 article_card radius_20">
                <div className="card-body text-start p-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="icon_box">
                      <img
                        src="./assets/image/resources_icn1.png"
                        className="icon_box_img mx-auto d-block"
                      />
                    </div>
                    <div className="ms-4">
                      <h3 className="my-2 article_head1">Expert Team</h3>
                      <p className="text-muted">
                        Our talented team of developers, designers, branding
                        experts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card bg2 article_card radius_20">
                <div className="card-body text-start p-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="icon_box">
                      <img
                        src="./assets/image/resources_icn3.png"
                        className="icon_box_img mx-auto d-block"
                      />
                    </div>
                    <div className="ms-4">
                      <h3 className="my-2 article_head1">Innovation</h3>
                      <p className="text-muted mb-0">
                        Innovation often starts with creative thinking, the
                        ability to generate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card bg4 article_card radius_20">
                <div className="card-body text-start p-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="icon_box">
                      <img
                        src="./assets/image/resources_icn4.png"
                        className="icon_box_img mx-auto d-block"
                      />
                    </div>
                    <div className="ms-4">
                      <h3 className="my-2 article_head1">Client-Centric</h3>
                      <p className="text-muted mb-0">
                        Your success is our success, and we are dedicated to
                        your satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
}

export default Resources;
