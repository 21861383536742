import React, { useState } from 'react'
import Boxcard from './Boxcard';
import Topheader from './Topheader';
import Navbar from './Navbar';
import Footer from './Footer';


function Industries() {
  const [currentFilter, setCurrentFilter] = useState('all');

    const articles = [
        {
            id: 1,
            category: 'social-media',
            title: 'Harmonizing Your Digital Presence',
            date: 'Sep 8, 2023',
            content: "In the ever-evolving symphony of social media, orchestrating a compelling and harmonious digital presence is key to capturing your..."
        },
        {
            id: 2,
            category: 'growth',
            title: 'Strategies for Scaling Your Online Presence',
            date: 'Sep 8, 2023',
            content: "In today's competitive digital landscape, achieving growth is not just a goal—it's a necessity..."
        },
        {
            id: 3,
            category: 'marketing',
            title: '5 Trends Reshaping Marketing Strategies',
            date: 'Sep 8, 2023',
            content: "In the ever-evolving landscape of marketing, adaptation is the key to survival. As digital frontiers continue..."
        }
    ];

    const handleFilterChange = (filter) => {
        setCurrentFilter(filter);
    };
  return (
    <div>
         <Topheader />
      <Navbar />
      <section className='py-5'>
        <div className='container text-center'>
            <div className='row justify-content-center'>
              <div className='col-12 col-md-7'>
                  <h5 className='sub_title'><span className='title_line'></span>Industries<span className='title_line'></span></h5>
                  <h1 className='mt-3 main_heading'>Navigating the Digital Sphere</h1>
                  <p className='fs_20 px-md-5 mx-md-3'>Welcome to ElevateSphere's blog, where we dive into the ever-evolving world of digital innovation.</p>
              </div>
              
            </div>
            <div className='row justify-content-center'>
            <div className='col-12 col-md-4'>
              <div className="input-group search_box">
                    <input type="search" class="form-control search_input" placeholder="Search for articles.."/>
                    <button type="button" class="btn search_btn">Search</button>
                  </div>
              </div>
              </div>

          </div>  
      </section>   

      <section className='py-5'>
        <div className='container text-center px-md-4'>
            <div className='row g-4 justify-content-center'>
              
              <div className='col-12 col-md-6'>
                  <div className='card industry_card'>
                    <div className='card-body text-start p-4'>
                    <div className='bg-white rounded p-5 mb-4'>
                      <img src='./assets/image/icn1.png' className='card_icn mx-auto d-block'/>
                    </div>
                    <span className='search_btn'>Social Media</span>
                    <span className='ms-3 text-white'>Sep 8, 2023</span>
                    <h2 className='card_head pe-md-5 my-3'>Harmonizing Your Digital Presence</h2>
                    <p className='text-muted fs_20 pe-md-5'>In the ever-evolving symphony of social media, orchestrating a compelling and harmonious digital presence is key to capturing your audience's attention.</p>
                    </div>
                  </div>
              </div>

              <div className='col-12 col-md-6'>
                  <div className='card industry_card'>
                    <div className='card-body text-start p-4'>
                    <div className='bg-white rounded p-4 mb-4'>
                      <img src='./assets/image/icn2.png' className='card_icn mx-auto d-block'/>
                    </div>
                    <span className='search_btn'>Content</span>
                    <span className='ms-3 text-white'>Sep 8, 2023</span>
                    <h2 className='card_head pe-md-5 my-3'>How to reach out for guest post to increase your seo authority</h2>
                    <p className='text-muted fs_20 pe-md-5'>In the ever-evolving symphony of social media, orchestrating a compelling and harmonious digital presence is key to capturing your audience's attention.</p>
                    </div>
                  </div>
              </div>

            </div>
          </div>  
      </section>  

      <section className='py-5'>
            <div className='container text-center'>

                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-6">
                        <h1 className="article_head text-start px-4">Latest Articles</h1>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className='d-flex justify-content-between px-md-4 direction_flex'>
                            <button className={`btn btn-outline-dark radius_20 industry_btn px-md-4 ${currentFilter === 'all' ? 'active' : ''}`} onClick={() => handleFilterChange('all')}>All</button>
                            <button className={`btn btn-outline-dark radius_20 industry_btn px-md-4 ${currentFilter === 'growth' ? 'active' : ''}`} onClick={() => handleFilterChange('growth')}>Growth</button>
                            <button className={`btn btn-outline-dark radius_20 industry_btn px-md-4 ${currentFilter === 'marketing' ? 'active' : ''}`} onClick={() => handleFilterChange('marketing')}>Marketing</button>
                            <button className={`btn btn-outline-dark radius_20 industry_btn px-md-4 ${currentFilter === 'social-media' ? 'active' : ''}`} onClick={() => handleFilterChange('social-media')}>Social Media</button>
                        </div>
                    </div>
                </div>

                <div className='row justify-content-center'>
                    {articles
                        .filter(article => currentFilter === 'all' || article.category === currentFilter)
                        .map(article => (
                            <div key={article.id} className='col-12 col-md-4'>
                                <div className='card article_card'>
                                    <div className='card-body text-start p-4'>
                                        <div className={`article_icn_bg${article.id} rounded p-5 mb-4`}>
                                        <img src={`./assets/image/icn${article.id}.png`} className='card_icn mx-auto d-block'/>
                                        </div>
                                        <span className='search_btn'>{article.category}</span>
                                        <span className='ms-3 text-muted'>{article.date}</span>
                                        <h3 className='my-3 article_head1'>{article.title}</h3>
                                        <p className='text-muted'>{article.content}<a href='#' className='default_text'>Learn More</a></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

            </div>
        </section>  
      
<Footer/>
      

    </div>
  )
}

export default Industries
