import React, { useEffect, useState } from "react";
import { getallBlogsPost } from "../helpers/api";

function Blog() {
  const [blogData, setData] = useState([]);
  useEffect(() => {
    getallBlogsPost().then((res) => {
      console.log(res, "res::");
      setData(res);
    });
  }, []);
  return (
    <section className="py-5">
      <div className="container text-center">
        <div className="row align-items-end">
          <div className="col-12 col-md-6">
            <h5 className="text-start text_center">
              <span class="title_line"></span>Blog
            </h5>
            <h1
              className="text-start text_center"
              style={{ fontWeight: "600" }}
            >
              Navigating the Digital Sphere
            </h1>
          </div>
          <div className="col-12 col-md-6 text-end text_center">
            <a
              href="https://blog.trustid.one/"
              className="btn home_bt px-5"
              style={{ borderRadius: "25px" }}
            >
              See All
            </a>
          </div>
        </div>

        <div className="row justify-content-center">
          {blogData?.map((res) => {
            return (
              <div className="col-12 col-md-4">
                <div className="card article_card">
                  <div className="card-body text-start p-4">
                    <div className=" rounded mb-3">
                      <img
                        src={`https://blog.trustid.one/uploads/${res?.post_media}`}
                        className="card_icn mx-auto d-block"
                      />
                    </div>
                    <span className="search_btn">{res?.tag_name}</span>
                    <span className="ms-3 text-muted"> {res?.updated_at}</span>
                    <h3 className="my-3 article_head1">
                     {res?.post_title}
                    </h3>
                    <p className="text-muted">
                    {res?.post_desc}
                      <a href="https://blog.trustid.one/" className="default_text">
                        Learn More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Blog;
