// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Contact from './Contact';

import About from './About';
import Aboutus from './Aboutus';

import Cardtree from './Cardtree';
import Boxcard from './Boxcard';
import Blog from './Blog';
import Services from './Services';
import Productcard from './Productcard';
import Features from './Features';
import Footer from './Footer';
import Topheader from './Topheader';
import Navbar from './Navbar';






function Home() {
  return (
    <>
      <Topheader />
      <Navbar />
      <About />
      <Cardtree />
      <Aboutus />
      <Services />
      <Productcard />
      <Features />

      {/* <Boxcard /> */}
      <Blog />
      <Footer />

    </>
  );
}

export default Home;
