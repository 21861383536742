import React from 'react';

function Boxcard() {
  return (
    <section className='py-5 my-5'>
        <div className='container'>
            <div className='card contact_bg radius_20 p-md-4 article_card'>
              <div className='card-body'>
              <div className='row justify-content-end'>
              
              <div className='col-12 col-md-7 py-3'>
              <h5 className='sub_title'><span className='title_line'></span>Contact</h5>
                <h1 className='contact_heading'>Ready to grow your startup? Get in touch today!</h1>

                <a href='/Contact' className='btn default_btn py-2 my-3'>Get in touch <i className="fa-solid fa-arrow-right ms-2"></i></a>
              </div>
            </div>
              </div>
            </div>
          </div>  
      </section>
  );
}

export default Boxcard;