import React from "react";
import Faq from "./Faq";
import Lottie from "react-lottie";
import imagecontact from "../components/image/customer_support.json";
import Topheader from "./Topheader";
import Navbar from "./Navbar";
import Footer from "./Footer";

function Contact() {
  function onlyOne() {
    var checkboxes = document.getElementsByName("project_category");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    this.checked = true;
  }
  return (
    <>
      <Topheader />
      <Navbar />
      <div className="contact_area">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12 review_block contact_block">
              <div>
                <div className="contact_titt">Contact form</div>
                <h1>
                  Contact
                  <br />
                  sales team
                </h1>
                <p>
                  Whether you have questions, need support, or want to learn
                  more about our identity verification solutions, we're here to
                  help. Reach out via the form below, email, or phone. Our
                  dedicated team is committed to providing the assistance you
                  need to secure and simplify your digital interactions.
                  <br />
                  <br />
                  For customer support, call +917985202681 or send an email to{" "}
                  <em className="green fst-normal">
                    <a href="#">info@trustid.one</a>
                  </em>{" "}
                  Our registered office address is{" "}
                  <strong>NAYGON TECHNOLOGIES PRIVATE LIMITED</strong> <br />
                  <strong> Address:</strong>
                  51 GANPATI NAGAR, DANDI, NAINI ALLAHABAD Allahabad UP 211008
                  IN
                </p>
              </div>
              <div className="row mr-3">
                <div className="press_jsonnew">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: imagecontact,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 review_block contact_block">
              <form>
                <input type="hidden" name="csrf_test_name" value="" />
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="form-d">
                      <label>Project Name</label>
                      <span className="hs-form-required">*</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Project Name"
                        name="project"
                        id="project"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-d">
                      <label>Your Name</label>
                      <span className="hs-form-required">*</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name"
                        name="name"
                        id="name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-d">
                      <label>Email</label>
                      <span className="hs-form-required">*</span>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        id="email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-d">
                      <label>Phone</label>
                      <span className="hs-form-required">*</span>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="0000-000-000"
                        name="contact"
                        id="phone"
                        pattern="[0-9]{10}"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-d">
                      <label>Whatsapp</label>
                      <span className="hs-form-required">*</span>
                      <input
                        type="tel"
                        className="form-control"
                        name="whatsapp"
                        placeholder="0000-000-000"
                        id="wphone"
                        pattern="[0-9]{10}"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="form-d">
                      <label>Telegram</label>
                      <span className="hs-form-required">*</span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Telegram"
                        name="telegram"
                        id="telegram"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-d">
                      <label>Project Category</label>
                      <span className="hs-form-required">*</span>

                      <div>
                        <ul
                          role="checkbox"
                          className="inputs-list multi-container"
                        >
                          <li className="hs-form-checkbox" role="checkbox">
                            <label>
                              <input
                                className="checkboxes"
                                type="checkbox"
                                name="project_category"
                                value="Wallet"
                                onClick={onlyOne}
                              />
                              <span>Wallet</span>
                            </label>
                          </li>
                          <li className="hs-form-checkbox" role="checkbox">
                            <label>
                              <input
                                className="checkboxes"
                                type="checkbox"
                                name="project_category"
                                value="Asset Management Tool"
                                onClick={onlyOne}
                              />
                              <span>Asset Management Tool</span>
                            </label>
                          </li>

                          <li className="hs-form-checkbox" role="checkbox">
                            <label className="hs-form-checkbox-display">
                              <input
                                className="checkboxes"
                                type="checkbox"
                                name="project_category"
                                value="other dApps"
                                onClick={onlyOne}
                              />
                              <span>other</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <div className="form-d">
                      <label>
                        Please select if your project is in any way related to:
                      </label>
                      <span className="hs-form-required">*</span>

                      <div>
                        <ul
                          role="checkbox"
                          className="inputs-list multi-container"
                        >
                          <li className="hs-form-checkbox" role="checkbox">
                            <label>
                              <input
                                className="checkboxes"
                                type="checkbox"
                                name="project_category"
                                value="Wallet"
                                onClick={onlyOne}
                              />
                              <span>Adult content</span>
                            </label>
                          </li>
                          <li className="hs-form-checkbox" role="checkbox">
                            <label>
                              <input
                                className="checkboxes"
                                type="checkbox"
                                name="project_category"
                                value="Asset Management Tool"
                                onClick={onlyOne}
                              />
                              <span>Not applicable</span>
                            </label>
                          </li>
                          <li className="hs-form-checkbox" role="checkbox">
                            <label>
                              <input
                                className="checkboxes"
                                type="checkbox"
                                name="project_category"
                                value="NFT Marketplace"
                                onClick={onlyOne}
                              />
                              <span>Drugs or supplements</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12">
                    <label>Do you want to launch within 15 days?</label>
                    <span className="hs-form-required">*</span>
                    <div>
                      <ul
                        required=""
                        role="checkbox"
                        className="inputs-list multi-container-radio"
                      >
                        <li className="hs-form-radio" role="radio">
                          <label>
                            <input
                              className="hs-input"
                              type="radio"
                              name="launch_15_days"
                              id="launch_15_days_yes"
                              value="Yes"
                              required=""
                            />
                            <span>Yes</span>
                          </label>
                        </li>
                        <li className="hs-form-radio" role="radio">
                          <label>
                            <input
                              className="hs-input"
                              type="radio"
                              name="launch_15_days"
                              id="launch_15_days_no"
                              value="No"
                              required=""
                            />
                            <span>No</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 mt-4 mb-5">
                    <input
                      type="submit"
                      className="hs-button primary large"
                      name="btn_ltab"
                      id="checkBtn"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Faq />
      <Footer />
    </ >
  );
}

export default Contact;

