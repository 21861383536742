import React from "react";

function Productcard() {
  return (
    <section className="my-5 py-5">
      <div className="container" style={{ borderRadius: "15px" }}>
        <div className="row">
          <div className="col-12 col-md-6" style={{ marginTop: "9px" }}>
            <div className="card card_border radius1">
              <div className="card-body text-start p-3">
                <h5>
                  <span className="title_line"></span>Our Product
                </h5>
                <h1 className="about_head">Trust Id Product Suite</h1>
                <p className="main_headpoint">
                  Dive into the world of Trust Id, where we offer a cutting-edge
                  suite of products designed to revolutionize how you manage
                  identity verification and access control. From the bustling
                  retail environment to secure door access, our products are
                  tailored to meet the diverse needs of our clientele.
                  <br />   <br />
          
                  Trust Id's innovative approach to identity verification and
                  access control is redefining the way businesses and
                  individuals secure their digital interactions. By offering a
                  suite of products that are not only advanced in their
                  technological capabilities but also designed with user
                  experience in mind, Trust Id ensures that the process of
                  verifying identities is seamless, efficient, and highly
                  secure. This dedication to excellence and innovation places
                  Trust Id as a leader in the digital security domain, catering
                  to a wide array of industries with diverse needs and
                  challenges.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 " style={{ marginTop: "9px" }}>
            <div className="row">
              <div className="col-12 col-md-6" style={{ marginTop: "9px" }}>
                <div className="card cardbg5">
                  <div className="card-body text-center">
                    <img
                      src="./assets/image/sound box (1) 1.png"
                      style={{ width: "60px" }}
                    />
                    <h5 className="mt-3 product_heading">TrustBox</h5>
                    <p className="product_para">
                      TrustBox is specifically tailored for businesses and
                      organizations looking to enhance their KYC and identity
                      verification procedures. Designed as an audio verification
                      companion, TrustBox provides immediate audible feedback
                      upon successful identity verification, streamlining
                      operations and bolstering security without involving
                      payment transactions.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-md-6 mt-md-5"
                style={{ marginTop: "9px" }}
              >
                <div className="card cardbg6">
                  <div className="card-body text-center">
                    <img
                      src="./assets/image/Group 561.png"
                      style={{ width: "60px" }}
                    />
                    <h5 className="mt-3 product_heading">
                      Point of Access (POA)
                    </h5>
                    <p className="product_para">
                      The POA system is a sophisticated combination of access
                      cards and machines designed for controlled entry to
                      facilities. It utilizes advanced encryption and identity
                      verification technologies to ensure that only authorized
                      personnel can gain entry, enhancing security for
                      businesses and institutions.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6" style={{ marginTop: "9px" }}>
                <div className="card cardbg7">
                  <div className="card-body text-center">
                    <img
                      src="./assets/image/card 1.png"
                      style={{ width: "60px" }}
                    />
                    <h5 className="mt-3 product_heading">
                      Door Access Kit (DAC)
                    </h5>
                    <p className="product_para">
                      Our Door Access Kit is a comprehensive solution for secure
                      and efficient door access control. Utilizing Trust Id's
                      trusted verification technology, the DAC ensures that
                      entry is granted only to verified individuals, making it
                      perfect for residential buildings, office spaces, and
                      secure areas.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-md-6 mt-md-5"
                style={{ marginTop: "9px" }}
              >
                <div className="card cardbg8">
                  <div className="card-body text-center">
                    <img
                      src="./assets/image/QR code Stand by.png"
                      style={{ width: "50px" }}
                    />
                    <h5 className="mt-3 product_heading">Trust QR Standy</h5>
                    <p className="product_para">
                      Trust QR Standy is a standalone verification device that
                      displays a dynamic QR code for contactless identity
                      verification and transaction approval. Designed for
                      versatility, it can be used in various settings, including
                      retail, hospitality, and corporate environments, offering
                      a quick and secure verification process.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Productcard;
